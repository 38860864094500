import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  stepper: {
    marginBottom: theme.spacing(2),
  },
  mapUrl: {
    width: "100%", 
    [theme.breakpoints.up('md')]: {
      height: "600px",
    },
    [theme.breakpoints.only('md')]: {
      height: "500px",
    },
    [theme.breakpoints.down('md')]: {
      height: "300px",
    },
    backgroundPosition: "center", 
    backgroundSize: "contain", 
    backgroundRepeat: "no-repeat",
    backgroundColor: "#f4f4f4"
  },
  colapseWrapperInner: {
    width: "100%",
  }

}));

export default useStyles;