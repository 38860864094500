import React, { useEffect } from 'react';
import Page from './page';
import { connect } from "react-redux";
import { authActions } from '../../../redux/actions';

const _Routes = (props) => {

  useEffect(()=>{
    props.load();
  },[]);

  return (
      <Page {...props}/>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  };
}

const mapDispatchToProps = {
  load: authActions.load,
}

export const Routes = connect(mapStateToProps, mapDispatchToProps)(_Routes);

export default Routes;