import React from 'react';
import useStyles from './style';
import { Switch, Route, Redirect } from "react-router-dom";
import { AppBar, Footer } from "../";
import { Container, CssBaseline } from "@material-ui/core";

const Dashboard = React.lazy(() => import('../Dashboard'));
const Home = React.lazy(() => import('../Home'));
const LoginLayout = React.lazy(() => import('../LoginLayout'));
const Logout = React.lazy(() => import('../Logout'));


const Page = ({ auth }) => {
  const classes = useStyles();

  return (
    <>     
      <CssBaseline />
      <AppBar/>
      <Container maxWidth={false} className={classes.container}>
        {auth.loaded && 
        <React.Suspense fallback={<span>Loading...</span>}>
        {!auth.isAuthenticated &&
          <Switch>
            <Route path="/Home" render={() => <Home/>}/>
            <Route path="/Login" render={() => <LoginLayout/>} />
            <Route path="/Logout" render={() => <Logout/>} />  
            <Route exact path="/" render={() => <Home/>} />       
            <Route exact path="*" render={() => <Redirect to="/"/>} />               
          </Switch>
        }
        {auth.isAuthenticated &&
          <Switch>
            <Route exact path="/Home" render={() => <Home/>} />   
            <Route exact path="/Logout" render={() => <Logout/>} />  
            <Route path="/Dashboard/:page/:key" render={() => <Dashboard/>} />  
            <Route path="/Dashboard/:page" render={() => <Dashboard/>} />  
            <Route exact path="/" render={() => <Home/>} />              
          </Switch>
        }
        </React.Suspense>
        }
      </Container>
      <Footer/>
      
    </>
  );
}

export default Page;


