import React from 'react';
import Grid from '@material-ui/core/Grid';
import useStyles from './style';
import Paper from '@material-ui/core/Paper';
import { Login, Novedad } from "../"
import {BullhornIcon, EnvelopeOpenTextIcon, LaptopIcon, StarIcon} from '../../helpers/icons/';

const Page = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        
        <Grid item xs={12} md={8}>
          <Paper elevation={0} className={classes.paper}>
            <Novedad 
              icono = {<BullhornIcon secondaryColor="#2aaede" primaryColor="#1f5772"/>}
              titulo = "¡Entérate primero de las nuevas ofertas!"
              contenido = "Guarda tu búsqueda y recibí las ultimas novedades en tu email. Este servicio te permitirá conocer que nadie las nuevas propiedades que cumplan con las características del inmueble que estás buscando."
            />
            <Novedad 
              icono = {<LaptopIcon secondaryColor="#2aaede" primaryColor="#1f5772"/>}
              titulo = "Publicá tu propiedad"
              contenido = "Publicá tu aviso en Quintuus y comenzá a recibir contactos de interesados."
            />
            <Novedad  
              icono = {<StarIcon secondaryColor="#2aaede" primaryColor="#1f5772"/>}
              titulo = "Guardá tus propiedades favoritas"
              contenido = "Este servicio permite guardar todos aquellos avisos que te resulten de interés y luego podrás acceder a ellos desde tu cuenta de manera fácil y rápida."
            />
            <Novedad 
              icono = {<EnvelopeOpenTextIcon secondaryColor="#2aaede" primaryColor="#1f5772"/>}
              titulo = "Recibí novedades y noticias"
              contenido = "Recibí nuestro newsletter con las últimas novedades y noticias en tu email."
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Login redirectUrl="/Dashboard/Publicar"/>
        </Grid>
      </Grid>
    </div>
  );
};


export default Page;