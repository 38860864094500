import React, { useEffect, useState } from 'react';
import Page from './page';
import { FormikProvider, useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { connect } from "react-redux";
import { authActions, appBarActions } from "../../../redux/actions";
import { useSnackContext } from '../../contexts/Snack';

const _Buscador = (props) => {
  
  let history = useHistory();
  const {openSuccess, openError} = useSnackContext();
  const [ data, setData ] = useState({});

  const validationSchema = yup.object({
    ubicacion: yup
      .string('Ingrese una ubicación o el nombre de una quinta')
      .required('La ubicación es requerida')
      .max(255, "Puede ingresar hasta 255 caracteres"),
    llegada: yup
      .date()
      .required("Debe ingresar una fecha de ingreso"),
    salida: yup
      .date()
      .required("Debe ingresar una fecha de salida"),
    cantidadPersonas: yup
      .number("Debe ingresar un número"),
    aceptaMascotas: yup
      .bool(),
    });

  const handleControlChange = {
  }
  
  const handleChange = (event) => {
    console.log(event);
    handleControlChange[event.target.name] && handleControlChange[event.target.name](event.target.value); 
    //formik.handleChange(event);
    formik.setFieldValue(event.target.name, event.target.value);
  }
  
  const formik = useFormik({
    initialValues: {
      ubicacion: '',
      llegada: undefined,
      salida: null,
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      props.buscar(
        values,
        ()=>{
          formik.setSubmitting(true);
        },
        (response)=>{
          formik.setSubmitting(false);
          //acciones si busca bien
        },
        (error)=>{
          formik.setSubmitting(false);
          openError("Error en el login");
        },
      );
    },
  });

  return (
      <Page title="Buscar quintas" subTitle="Ingrese la ubicación, la fecha de llegada y la de salida" {...{formik, data, handleChange}} />
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  };
}

const mapDispatchToProps = {

}

export const Buscador = connect(mapStateToProps, mapDispatchToProps)(_Buscador);

export default Buscador;