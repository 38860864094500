import React from "react";
import BaseIcon from "./_BaseIcon";

export const HouseUserIcon = ({ className, primaryColor, secondaryColor, width = "60", height = "60" }) => {
  return (
    <BaseIcon 
        className={className}
        width={width}
        height={height}
        viewBox="0 0 576 512"
    >
      <path fill={primaryColor} d="M570.61,240,512,187.37V56a24,24,0,0,0-24-24H392a24,24,0,0,0-24,24v2.08l-53.44-48C308.28,4.53,296.39,0,288,0s-20.28,4.53-26.56,10.09L5.39,240A18.21,18.21,0,0,0,0,252a18.47,18.47,0,0,0,4,10.61l10.62,12a18.15,18.15,0,0,0,12,5.37,18.54,18.54,0,0,0,10.63-4l26.75-24V472a40,40,0,0,0,40,40H472a40,40,0,0,0,40-40V251.85l26.75,24a16,16,0,0,0,22.59-1.33L572,262.59A18.47,18.47,0,0,0,576,252,18.21,18.21,0,0,0,570.61,240ZM464,464H416V448a96,96,0,0,0-96-96H256a96,96,0,0,0-96,96v16H112V208.76l176-158,176,158Zm0-319.72-48-43.1V80h48ZM224,256a64,64,0,1,0,64-64A64,64,0,0,0,224,256Z" ></path>
    </BaseIcon>
  );
}