import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import { Button, Dialog, DialogTitle, Grid } from "@material-ui/core";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { DatePicker, SelectGiz, SimpleAccordion, SimpleActionButton, SimpleSelectGiz, SwitchGiz, TextGiz, TextNumberGiz } from "../../../helpers";
import { useFormik } from "formik";
import * as yup from "yup";

const localizer = momentLocalizer(moment);

const validationSchema = yup.object({
  desde: yup.date(),
  hasta: yup.date(),
  precio: yup.number(),
  disponible: yup.bool(),
})

const diasFinDeSemanaOptions = [
  {value: 0, label: "Viernes y sábado"}, 
  {value: 1, label: "Viernes, sábado y domingo"}, 
  {value: 2, label: "Sábado y domingo"}
];

export const Paso4 = ({onBack, onNext, completed, formik, classes = {}, handleChangePanelActivo, panelActivo, tiposCamas}) => {

  const [fecha, setFecha] = useState(new Date());

  const {habilitadoHasta, diasFinDeSemana, precioBase, precioFinDeSemana, fechas} = formik.paso4.panel1.values;
  
  const setRango = (values) => {
    const fecha = fechas.find(x => (moment(x.fecha).isBetween(values.desde, values.hasta, undefined, "[]"))); 
    rango.setValues({...values, precio: fecha ? fecha.precio : ''});
  }

  const [fechasPrecioBase, setFechasPrecioBase] = useState([]);

  useEffect(()=>{
  
    let fechasPrecioBase2 = [];
    for(let fecha = moment().toDate(); fecha <= habilitadoHasta.toDate(); fecha = moment(fecha).add(1, 'd').toDate()){
      fechasPrecioBase2.push({
        libre: true,
        precio: precioBase || 0,
        tipo: "precioBase",
        fecha: fecha,
        start: fecha,
        end: fecha,
      });
    }
    setFechasPrecioBase(fechasPrecioBase2);
  
  }, [])

  const rango = useFormik({
    initialValues: {
      desde: null,
      hasta: null,
      precio: '',
      libre: true,
      dialog: false,
    },
    validationSchema: validationSchema,
    validateOnMount: true,
    onSubmit: values => { 

      console.log(values)
      const {desde, hasta, precio, libre} = values;

      let fechas2 = fechas.map(x => (moment(x.fecha).isBetween(desde, hasta, undefined, "[]") ? null : x)).filter(x => x !== null);
      const tipo = "precio";

      for(let fecha = moment(desde).toDate(); fecha <= hasta; fecha = moment(fecha).add(1, 'd').toDate()){
        fechas2.push({libre, precio, tipo, 
          fecha: fecha,
          start: fecha,
          end: fecha
        });
      }

      setEvents(createEvents(fechas2));
      formik.paso4.panel1.setFieldValue("fechas", fechas2);
      rango.resetForm();
    },
  });

  const activarPanel = (panel) => {
    handleChangePanelActivo(panel);
  }

  const handlePanel1_Volver = () => {
    onBack();
  }

  const handlePanel1_Siguiente = () => {
    formik.paso4.panel1.submitForm().then(()=>{
      activarPanel('panel1');
    })
  }

  const fechasIguales = (fecha1, fecha2) => {
    return fecha1 && fecha2 && fecha1.getDate() === fecha2.getDate() && fecha1.getMonth() === fecha2.getMonth() && fecha1.getYear() === fecha2.getYear();
  }

  const createEvents = (fechas = []) => {
    /*const events = [
      ...fechas, 
      ...fechasPrecioBase.filter(
          x => fechas.every(
            y => fechasIguales(y.fecha, x.fecha)
          )
        )
    ];*/
    return fechasPrecioBase.map(x => {
      return fechas.find(y => fechasIguales(y.fecha, x.fecha)) || x;
    });
  }

  const mapearFechas = (fechas) => fechas.map(x => ({
    ...x,
    fecha: moment(x.fecha).toDate(),
    start: moment(x.fecha).toDate(),
    end: moment(x.fecha).toDate(),
  }))

  const [events, setEvents] = useState(createEvents(mapearFechas(fechas)));

  const handleDialogClose = () => {
    setRango({desde: null, hasta: null, dialog: false});
  } 
  
  const handleOcupadoClick = (e) => {
    e.preventDefault();
    rango.setFieldValue("libre", false);
    rango.handleSubmit();
  }

  const handleLibreClick = (e) => {
    e.preventDefault();
    rango.setFieldValue("libre", true);
    rango.handleSubmit();
  }
  
  return (
    <>
      <SimpleAccordion title="Calendario" subTitle="" name="panel1" activePanel={panelActivo}
        actions={[
          <SimpleActionButton label="Volver" onClick={handlePanel1_Volver} />,
          <SimpleActionButton label="Siguiente" onClick={handlePanel1_Siguiente} />,
        ]}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} lg={8}>
            <Dialog onClose={handleDialogClose} open={rango.values.dialog} >
              <DialogTitle>Actualice el precio o la disponibilidad de la selección</DialogTitle>
              <Grid container spacing={2} style={{padding:"16px"}}>
                <Grid item xs={8}>
                  <TextGiz type="number" name="precio" label="Precio" formik={rango} autoFocus={true}  />
                </Grid>
                <Grid item xs={12}>
                  <Button style={{margin:"8px"}} variant="contained" onClick={handleLibreClick} >Libre</Button>
                  <Button style={{margin:"8px"}} variant="contained" onClick={handleOcupadoClick} >Ocupado</Button>
                </Grid>
                
              </Grid>
            </Dialog>
            <Calendar
              min={new Date()}
              max={habilitadoHasta}
              titleAccessor="Calendario"
              views={['month']}
              value={fecha}
              localizer={localizer}
              defaultDate={new Date()}
              defaultView="month"
              events={events}
              style={{ height: "100vh" }}
              selectable={true}
              eventPropGetter= {(event, start, end, isSelected) => ({ style: {minHeight:"20px;", backgroundColor: event.libre ? "#82ad60" : "#a3a3a3" } })}
              components={{
                event: ({event})=>{
                  return(
                    <div style={{height: "100%", borderRadius: "0px"}}>
                      $ {event.precio} <br/> {event.tipo} {event.libre ? "libre" : "ocupado"}
                    </div>
                  )
                },
                dateCellWrapper: (props) => {
                  const {children, value} = props;
                  //console.log(props)
                  const isInRange = rango.values.desde && rango.values.hasta && moment(value).isBetween(rango.values.desde, rango.values.hasta, undefined, "[]");
                  return (
                    <div style={{flex:"1 1", backgroundColor: isInRange ? "var(--secondary-color)" : "transparent"}}><div></div></div>
                  )
                },
                
                /*
                header: (props) => {
                    
                  console.log({header: props})
                  return (<>header {props.isOffRange ? "si" : "no"}</>)
                }                  

                month: {
                  dateHeader: (props) => {
                    console.log({dateHeader: props})
                    return (<>date {props.isOffRange ? "si" : "no"}</>)
                  },
                }*/

              }}
              dayPropGetter={(date) => ({ style: {height: "100%"} })}
              /*onSelectEvent={handleOnSelectEvent}*/
              onSelectSlot={({action, start, end})=>{
                
                if(action === "click"){
                  const {desde, hasta} = rango.values;
                  if(desde && hasta && desde === hasta){
                    if(start > hasta){
                      setRango({desde, hasta: start, dialog: true});
                    }
                    if(end < rango.desde){
                      setRango({desde: end, hasta, dialog: true});
                    }                   
                  }
                  if(!desde && !hasta || desde !== hasta){
                    setRango({desde: start, hasta: end, dialog: false});
                  }
                }

                if(action === "select"){
                  setRango({desde: start, hasta: end, dialog: true});
                }
                
                if(action === "doubleClick"){
                  setRango({desde: start, hasta: start, dialog: true});
                }

              }}

            />    
          </Grid>
          
          <Grid item xs={12} lg={4}>
            <Grid container spacing={2}>
              <Grid item xs={12}> 
                Configuración
              </Grid>
              <Grid item xs={12}> 
                <DatePicker label="Habilitado hasta" name="habilitadoHasta" type="date" formik={formik.paso4.panel1}/>
              </Grid>
              <Grid item xs={12}> 
                <TextGiz label="Precio base" name="precioBase" type="number" formik={formik.paso4.panel1}/>
              </Grid>
              <Grid item xs={12}> 
                <TextGiz label="Precio fin de semana" name="precioFinDeSemana" type="number" formik={formik.paso4.panel1}/>
              </Grid>
              <Grid item xs={12}> 
                <SimpleSelectGiz label="Noches del fin de semana" name="diasFinDeSemana" type="number" options={diasFinDeSemanaOptions} formik={formik.paso4.panel1}/>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

      </SimpleAccordion>

    </>
  );

}