import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  iconCell: {
    textAlign: "right",
    paddingRight: theme.spacing(1),
  }
}));

export default useStyles;