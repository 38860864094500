import React, { useEffect, useState } from "react"
import { Grid, IconButton, InputAdornment, InputBase, makeStyles, TextField, Typography} from "@material-ui/core"
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

const toInt = (value) => (value === "") ? 0 : parseInt(value);

const useStyles = (slim) => makeStyles((theme) => ({
  input: {
    textAlign: "center !important",
    fontSize: !slim ? "1.4em !important" : "1.1em !important",
    width: !slim ? "200px !important": "120px !important",
  }
}));

export const TextNumberGiz = ({_value, name, label, min = 0, max = Number.MAX_SAFE_INTEGER, error, helperText, formik, placeholder, onChange, autoFocus, disabled, style = {}, slim = false}) => {

  const classes = useStyles(slim)();

  const externalValue = formik ?  toInt(formik.values[name]) :  toInt(_value);
  
  const [value, setValue] = useState(externalValue);

  const [timer, setTimer] = useState(null);


  useEffect(()=>{
    timer !== null && clearTimeout(timer);
    setTimer( 
      formik ? setTimeout(actualizarFormik, 350) : onChange({target:{name, value: value}})
    );
  }, [value])

  useEffect(()=>{
    if(formik) {
      value !== formik.values[name] && setValue(externalValue);
    } else {
      value !== externalValue && setValue(externalValue);
    }
  }, [externalValue])

  useEffect(()=>{
    setValue(formik ? toInt(formik.values[name]) : _value);
  }, [])

  const actualizarFormik = () => {
    value !== toInt(formik.values[name]) && formik.setFieldValue(name, value);
  }

  const handleRemoveClick = () => {
    setValue(value - 1)
  }

  const handleAddClick = () => {
    setValue(value + 1)
  }
  
  const handleInputChange = (e) => {
    let newValue =  toInt(e.target.value);
    if(newValue > max){
      newValue = max;
    }
    if(newValue < min){
      newValue = min;
    }
    setValue(newValue)
  }

  return (


    <Grid container style={!slim ? { margin: 8, ...style } : { margin: 0 }}>
      {
        <Grid item xs={12} md={6}>
          <Typography padding={1}>
            {label} 
          </Typography>   
        </Grid>    
      }
      <Grid item xs={slim ? 12 : 11} md={slim ? 12 : 4}>
        <IconButton onClick={handleRemoveClick} disabled={value <= min || disabled}><RemoveCircleOutlineIcon /></IconButton>
        <InputBase
          classes={classes}
          autoFocus={autoFocus}
          type="number"
          size={slim ? "small" : "normal"}
          id={name}
          name={name}
          label={slim ? label : ""}
          placeholder={placeholder}
          value={value}
          onChange={handleInputChange}
          error={error || (formik && formik.touched[name] && Boolean(formik.errors[name]))}
          helperText={helperText || (formik && formik.touched[name] && formik.errors[name])}
          disabled={disabled} 
        />
        <IconButton onClick={handleAddClick} disabled={value >= max || disabled}><AddCircleOutlineIcon/></IconButton>

      </Grid>
      {!slim && 
        <Grid item xs={1} md={2}></Grid>
      }
    </Grid> 
  )
}

