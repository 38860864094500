import React from 'react';
import useStyles from './style';
import { Button, Card, CardActions, CardContent, CardMedia, Grid, Paper, Typography, useMediaQuery } from '@material-ui/core';

const Aviso = ({item}) => {
  const classes = useStyles();
  const isMobile = useMediaQuery(`(max-width: 760px)`);

  return (
    <div className={classes.rootAviso}>
      <Grid container key={item.id} spacing={4}>


        {!isMobile && 
          <Grid item xs={12}>
            <Card>
              <CardMedia
                sx={{ height: 140 }}
                image={item.fotoURL}
                title="Foto de la publicación"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                {item.titulo} {item.estado}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <p>Quinta para {item.cantidadPersonas} {item.cantidadPersonas == 1 ? "persona" : "personas"} en {item.direccion}, {item.codigoPostal} {item.localidad}, {item.provincia}, {item.pais}</p>
                  <p>Cuenta con {item.detalleEspacios}</p>
                  {item.precio > 0 &&
                    <p>$ {item.precio}</p>
                  }
                  {item.precio == 0 &&
                    <p>Sin precio</p>
                  }                

                  {item.porcentajeCarga < 100 &&
                    <p>Tu aviso esta al {item.porcentajeCarga}%</p>
                  }
                  <Grid container spacing={1}>
                    
                    <Grid item xs={3} className={classes.infoBox}>
                      <Paper className={classes.box}>
                        <Typography variant="body2" color="text.secondary">
                          <h3>PUBLICADO EL</h3>
                          <p>{item.fechaPublicacion}</p>
                        </Typography>
                      </Paper>
                    </Grid>

                    <Grid item xs={3} className={classes.infoBox}>
                      <Paper className={classes.box}>
                        <Typography variant="body2" color="text.secondary">
                          <h3>VENCIMIENTO</h3>
                          <p>{item.fechaVencimiento}</p>
                        </Typography>
                      </Paper>
                    </Grid>

                    <Grid item xs={3} className={classes.infoBox}>
                      <Paper className={classes.box}>
                        <Typography variant="body2" color="text.secondary">
                          <h3>VISTAS</h3>
                          <p>{item.vistas}</p>
                        </Typography>
                      </Paper>
                    </Grid>

                    <Grid item xs={3} className={classes.infoBox}>
                      <Paper className={classes.box}>
                        <Typography variant="body2" color="text.secondary">
                          <h3>CONSULTAS</h3>
                          <p>{item.consultas}</p>
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>

                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small">Modificar</Button>
                <Button size="small">Eliminar</Button>
              </CardActions>
            </Card>
          </Grid>
        }

        {isMobile && 
          <Grid item xs={12}>
            <Card>
              <CardMedia
                sx={{ height: 140 }}
                image={item.fotoURL}
                title="Foto de la publicación"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
                {item.titulo} {item.estado}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  <p>Quinta para {item.cantidadPersonas} {item.cantidadPersonas == 1 ? "persona" : "personas"} en {item.direccion}, {item.codigoPostal} {item.localidad}, {item.provincia}, {item.pais}</p>
                  <p>Cuenta con {item.detalleEspacios}</p>
                  {item.precio > 0 &&
                    <p>$ {item.precio}</p>
                  }
                  {item.precio == 0 &&
                    <p>Sin precio</p>
                  }                

                  {item.porcentajeCarga < 100 &&
                    <p>Tu aviso esta al {item.porcentajeCarga}%</p>
                  }
                  <h3>PUBLICADO EL</h3>
                  <p>{item.fechaPublicacion}</p>
                  <h3>VENCIMIENTO</h3>
                  <p>{item.fechaVencimiento}</p>
                  <h3>VISTAS</h3>
                  <p>{item.vistas}</p>
                  <h3>CONSULTAS</h3>
                  <p>{item.consultas}</p>

                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small">Modificar</Button>
                <Button size="small">Eliminar</Button>
              </CardActions>
            </Card>
          </Grid>
        }
      </Grid>
    </div>
  );
};

export default Aviso;