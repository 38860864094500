import React from 'react';
import Page from './page';
import useStyles from './style';

export const Index = () => {
  const classes = useStyles();
  return (  
    <div className={classes.root} >
      <Page />
    </div>
  )
};

