import React from 'react';
import useStyles from './style';
import { Grid } from '@material-ui/core';

const Page = ({children}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={0}>
        <Grid item xs={12} />
        <Grid item xs={12} md={1}></Grid>
        <Grid item xs={12} md={10} className={classes.mainPage}>
          {children}
        </Grid>
        <Grid item xs={12} md={1}></Grid>
      </Grid>
    </div>
  );
};

export default Page;