import React from 'react';
import Page from './page';

export const Popover = ({id, anchorEl, handleClose, children}) => {
  const open = Boolean(anchorEl);
  return (
      <Page {... {open, anchorEl, id, handleClose, children}}/>
  );
}

export default Popover;