import { FormControl, FormHelperText, InputLabel, makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Select from 'react-select'

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 120,
  },
}));

const selectStyles = (error) => {
  const border = `1px solid ${error ? '#f018a6' : '#c4c4c4'}`;
  return {
    menu: (provided, state) => ({
      ...provided,
      zIndex: 10000,
    }),
    valueContainer:(provided, state) => ({
      ...provided,
      margin: "8px",
    }),
    control: (provided, state) => ({
      ...provided,
      border,
    })
  }
}

export const MultiSelectGiz = (props) =>  {

  const {details = false, isMulti = true, name, label, caption, options, formik } = props;
  const error = formik &&  formik.errors[name];
  const hasError = Boolean(error);
  const classes = useStyles();
  const [state, setState] = useState({items: [], value: null, controlStyle: {}})
  
  useEffect(() => {
    let v = null;
    if(state && state.value){
      if(isMulti){
        v = state.value.map(x => x.value);
      }else{
        v = Array.isArray(state.value) ? state.value.length > 0  && state.value[0].value : state.value.value;
      }
      v !== formik.values[name] && formik.setFieldValue(name, v)
    }else{
      formik.setFieldValue(name, "")
    }
  }, [state.value])

  useEffect(() => {
    setState({...state, controlStyle: selectStyles(hasError)});
  }, [hasError])

  const handleChange = (value) => {
    setState({
      ...state,
      value
    })
  }

  useEffect(() => {
    setState({
      items: options,
      value: options.find(x => x.value == formik.values[name]),
      controlStyle: selectStyles(false),
    })
  }, [])

  return (
    <div  style={{ margin: 8 }}>
      <FormControl fullWidth variant="outlined" className={classes.formControl} error={hasError} >
        {Boolean(state.value) && 
          <InputLabel shrink={true} style={{paddingLeft: "4px", paddingRight: "4px"}} error={hasError}>
            {caption || label}
          </InputLabel>      
        }
        <Select
          isDisabled={details}
          isMulti={isMulti}
          styles={state.controlStyle}
          name={name}
          options={state.items}
          value={state.value}
          onChange={value => handleChange(value)}
          placeholder={caption || label}
        />
        {hasError &&
          <FormHelperText>{error}</FormHelperText>
        }
      </FormControl>
    </div>
  )
}