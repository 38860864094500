import { Button, Card, CardActions, CardContent, CardHeader, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputLabel, OutlinedInput, Select, Typography } from "@material-ui/core";
import { FieldArray } from "formik";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Cama } from "./cama";
import { experimentalStyled as styled } from '@material-ui/core/styles';
import { Cancel, Check, Edit, ExpandMore, MoreVert} from '@material-ui/icons';

export const getCapacidad = (_camas) => _camas.reduce((a, c) => a + (parseInt(c.cantidad) * parseInt(c.cantidadPersonas)),0);
export const getCantidadCamas = (_camas) => _camas.reduce((a, c) => a + parseInt(c.cantidad),0);

export const Dormitorio = ({index, key, camas, onChange, title="Dormitorio", showIndex  = true}) => {
  

  const [data, setData] = useState(camas);
  const [edit, setEdit] = useState(false);
  const [capacidad, setCapacidad] = useState(null);

  useEffect(()=>{
    setData(camas)
  },[camas])

  useEffect(()=>{
    setCapacidad(getCapacidad(data));
  },[data])

  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded((x) => !x);
  }

  const handleEditButtonClick = (e) => {
    setEdit(true);
  }

  const handleOkButtonClick = (e) => {
    setEdit(false);
    setExpanded(false);
    onChange(data.map((x) => ({...x, visible: x.cantidad > 0})));
  }

  const handleCancelButtonClick = (e) => {
    setEdit(false);
    setExpanded(false);
    setData(camas);
  }
  
  const handleCamaChange = (cama) => {
    setData(data.map((x) => x.nombre===cama.nombre ? cama : x));
  }

  
  return (
    <Card square>
      <CardHeader
        action={
          <>
            <Button  disabled={edit} onClick={handleEditButtonClick}>
              editar
            </Button>
            {/*!edit && 
              <IconButton aria-label="settings">
                <Edit />
              </IconButton>*/
            }
          </>
        }
        title={`${title} ${showIndex ? `${index+1}` : ''}`}
        subheader={`capacidad: ${capacidad==0 ? '-no seteado-' : capacidad}`}
      />
      {!edit && 
        <CardContent>
          <Typography variant="body2" color="text.secondary">
          {capacidad==0 ? 'Toque el botón editar para agregar camas a este lugar.' : 
            data.filter((cama)=>cama.visible && cama.cantidad>0).map((cama) =>`${cama.descripcion} x ${cama.cantidad}`).join(", ")
          }
          </Typography>
        </CardContent>
      }

      <Collapse in={edit} timeout="auto" unmountOnExit>
        <CardContent>
          {data.filter((cama)=>cama.visible).map((cama) =>
            <Cama cama={cama} onChange={handleCamaChange}/>
          )}
        </CardContent>
        <CardActions>
          <IconButton aria-label="settings"  onClick={handleOkButtonClick}>
            <Check />
          </IconButton>
          <IconButton aria-label="settings"  onClick={handleCancelButtonClick}>
            <Cancel />
          </IconButton>
          <ExpandMoreButton
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMore />
          </ExpandMoreButton>
        </CardActions>        
        <CardContent>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            {data.filter((cama)=>!cama.visible).map((cama) =>
              <Cama cama={cama} onChange={handleCamaChange}/>
            )}
          </Collapse>
        </CardContent>
      </Collapse>

    </Card>

  );
}

const ExpandMoreButton = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const DialogSelectCama = ({tiposCamas}) => {

  const [open, setOpen] = React.useState(false);

  const handleChange = (event) => {
    //setAge(Number(event.target.value) || '');
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  return (
    <div>
      <Button onClick={handleClickOpen}>Open select dialog</Button>
      <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
        <DialogTitle>Fill the form</DialogTitle>
        <DialogContent>
          <FormControl>
            <InputLabel htmlFor="demo-dialog-native">Age</InputLabel>
            <Select
              native
              value={10}
              onChange={handleChange}
              input={<OutlinedInput label="Age" id="demo-dialog-native" />}
            >
              <option aria-label="None" value="" />
              <option value={10}>Ten</option>
              <option value={20}>Twenty</option>
              <option value={30}>Thirty</option>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose}>Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

