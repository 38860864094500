import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  buttonQuintuus: {
    margin: theme.spacing(1),
  },
  aceptaMascotas:{
    margin: theme.spacing(1),
    textAlign: "center"
  }
}));

export default useStyles;