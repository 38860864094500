import React from 'react';
import useStyles from './style';
import { FormPublicacion } from '../../../components';

const Page = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <FormPublicacion title="" subTitle=""/>
    </div>
  );
};

export default Page;