import React from "react";
import BaseIcon from "./_BaseIcon";

export const LaptopIcon = ({ className, primaryColor, secondaryColor, width = "60", height = "60" }) => {
  return (
    <BaseIcon 
        className={className}
        width={width}
        height={height}
        viewBox="0 0 640 512"
    >
      <path fill={secondaryColor} d="M528 0H112a48.14 48.14 0 0 0-48 48v336h512V48a48.14 48.14 0 0 0-48-48zm-16 320H128V64h384z" ></path>
      <path fill={primaryColor} d="M512 64H128v256h384zm112 352H381.54c-.74 19.81-14.71 32-32.74 32H288c-18.69 0-33-17.47-32.77-32H16a16 16 0 0 0-16 16v16a64.19 64.19 0 0 0 64 64h512a64.19 64.19 0 0 0 64-64v-16a16 16 0 0 0-16-16z" ></path>
    </BaseIcon>
  );
}