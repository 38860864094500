import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

var firebaseConfig = {
  apiKey: "AIzaSyDjJr3ZuSHqfMTWSKpZw6uVuIaq3S2OHys",
  authDomain: "zippy-vigil-278418.firebaseapp.com",
  projectId: "zippy-vigil-278418",
  storageBucket: "zippy-vigil-278418.appspot.com",
  messagingSenderId: "1007810679982",
  appId: "1:1007810679982:web:4e8c4aa67a1498737e893f",
  measurementId: "G-Y1H6Y1CY4F"
};

!firebase.apps.length && firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
//const firestore = firebase.firestore();
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();

const mapUserFromFirebaseAuthToUser = (user) => {
  //const {displayName, email, photoURL } = user
  return user;
}

export const onAuthStateChanged = (onChange) => {
  return auth.onAuthStateChanged(user => {
    onChange(user);
  })
}

export const signInWithFacebook = () => {
  return auth.signInWithPopup(facebookAuthProvider);
};

export const signInWithGoogle = () => {
  return auth.signInWithPopup(googleAuthProvider);
};

