import { Stepper } from './stepper';
import React from 'react';
import useStyles from './style';

const Page = ({formik}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Stepper {...{classes, formik}}/>
    </div>
  );
};

export default Page;