import { makeStyles } from '@material-ui/core/styles';


import bg1 from "../../assets/img/bg/bg-1.jpg";
import bg2 from "../../assets/img/bg/bg-2.jpg";
import bg3 from "../../assets/img/bg/bg-3.jpg";
import bg4 from "../../assets/img/bg/bg-4.jpg";
import bg5 from "../../assets/img/bg/bg-5.jpg";
import bg6 from "../../assets/img/bg/bg-6.jpg";
import bg7 from "../../assets/img/bg/bg-7.jpg";
import bg8 from "../../assets/img/bg/bg-8.jpg";


const backgrounds = [bg1, bg2, bg3, bg4, bg5, bg6, bg7, bg8];


const random = (min, max) => Math.floor((Math.random() * (max - min + 1)) + min);



const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${backgrounds[random(0,7)]})`,
    backgroundPosition: "center",
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export default useStyles;