import { combineReducers } from "redux";
import auth from "./auth.reducer";
import appBar from "./appBar.reducer";
import avisos from "./avisos.reducer";
import {reducer as publicaciones} from "./publicaciones.reducer";

const rootReducer = combineReducers({
  auth,
  appBar,
  avisos,
  publicaciones,
})

export default rootReducer;