import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Divider, 
  Grid,
  Button,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AccordionActions from '@material-ui/core/AccordionActions';
import { SimpleAccordion, SimpleActionButton, TextGiz} from '../../../helpers';
import GoogleSuggest from './GoogleSuggest';
import GoogleMapEdit from './GoogleMap';
import { Edit } from '@material-ui/icons';


export const Paso1 = ({onBack, onNext, onSubmit, onUpdateData, loading, item, completed, formik, classes = {}, handleChangePanelActivo, panelActivo}) => {

  const {lat, lng, titulo, mapUrl, ubicacion, mapEdit, ubicacionConfirmada} = {...formik.paso1.panel1.values, ...formik.paso1.panel2.values, ...formik.paso1.panel3.values};

  const [map, setMap] = useState({init: false, lat: lat, lng: lng});

  const hayUbicacionConfirmada = Boolean(ubicacionConfirmada);

  useEffect(()=>{
    if(lat !== 0 && lng != 0){
      formik.paso1.panel2.setValues({
        ...formik.paso1.panel2.values,
        mapUrl: getMapUrl(lat, lng),
      })
      setMap({...map, lat: lat, lng: lng});
    }
  }, [lat, lng])

  const getMapUrl = (lat, lng) => {
    //_this.mapImgOffsetWidth = _this.mapImgOffsetWidth != 0 ? _this.mapImgOffsetWidth : _this.mapImg.nativeElement.offsetWidth;
    const latLng = `${lat},${lng}`;
    return "https://maps.googleapis.com/maps/api/staticmap?" + 
    "scale=2&size=600x300&" +
    "key=AIzaSyCXqMUH-2r3_AJm12HkGE8Mu9yfwqZv2PU&" + 
    "center=" + latLng + "&" +
    "zoom=15&" + 
    "markers=anchor:64,64%7Cicon:https://tinyurl.com/ysxjmzze%7C" + latLng + "&" + (new Date().toISOString());           
  }

  const handleChange = (panel) => (event, newExpanded) => {
    activarPanel(newExpanded ? panel : false)
  };

  const activarPanel = (panel) => {
    handleChangePanelActivo(panel);
  }

  const handlePanel1_Siguiente = () => {
    formik.paso1.panel1.submitForm().then(()=>{
      activarPanel('panel2');
    })
  }

  const handlePanel2_Volver = () => {
    activarPanel('panel1');
  }

  const handlePanel2_Siguiente = () => {
    formik.paso1.panel2.submitForm().then(()=>{
      activarPanel('panel3');
    })
  }

  const handlePanel3_Volver = () => {
    activarPanel('panel2');
  }

  const handlePanel3_Siguiente = () => {
    formik.paso1.panel3.submitForm().then(()=>{
      onNext();
    })
  }

  const handlePrediction = (prediction) => {
    formik.paso1.panel2.setValues({
      ...formik.paso1.panel2.values,
      lat: prediction.lat,
      lng: prediction.lng,
      ubicacionConfirmada: prediction.formatted_address,
    });
    
    //setMap({...map, init:true});

    console.log(prediction) // eslint-disable-line

    formik.paso1.panel3.setValues({
      ...formik.paso1.panel3.values,
      direccion: `${prediction.route} ${prediction.street_number}`.trim(),
      barrio: "",
      localidad: prediction.locality,
      partido: prediction.administrative_area_level_2,
      provincia: prediction.administrative_area_level_1,
      pais: prediction.country,
    });

  }

  const handleMapEditClick = () => {
    setMap({...map, init: true});
    formik.paso1.panel2.setValues({
      ...formik.paso1.panel2.values,
      mapEdit: true,
    });
  }

  const handleMapEditConfirm = () => {
    formik.paso1.panel2.setValues({
      ...formik.paso1.panel2.values,
      mapEdit: false,
      lat: map.lat,
      lng: map.lng,
    });
  }

  const handleMapEditCancel = (lat, lng) => {
    formik.paso1.panel2.setValues({
      ...formik.paso1.panel2.values,
      mapEdit: false,
    });
  }

  const handleMapChangePosition = (pos) => {
    setMap({...map, ...pos});
  }

  //postal_code: getFromResultComponent("postal_code", result),
  //postal_code_suffix: getFromResultComponent("postal_code_suffix", result),

  return (
    <>


      <SimpleAccordion title="Título de la Publicación" subTitle={titulo} name="panel1" activePanel={panelActivo}
        actions={[
          <SimpleActionButton label="Siguiente" onClick={handlePanel3_Siguiente} disabled={!formik.paso1.panel1.isValid || !formik.paso1.panel2.isValid || !formik.paso1.panel3.isValid} />,
        ]}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextGiz
              name="titulo"
              label="Título de la publicación"
              formik={formik.paso1.panel1}
              placeholder="Ingrese el título de la publicación"
            />

          </Grid>
 
          {!hayUbicacionConfirmada &&
            <Grid item xs={12}>
              <GoogleSuggest 
                onPrediction={handlePrediction}
                value={ubicacion}
                //setValue={(newValue)=>{formik.paso1.panel2.setFieldValue("ubicacion", newValue)}}
                input={(handleInputChange) => {                
                  return (<TextGiz
                    type="text"
                    name="ubicacion"
                    label="Ubicación"
                    formik={formik.paso1.panel2}
                    placeholder="Buscar en GoogleMaps"
                    onChange={(event)=>{
                      //formik.paso1.panel2.setFieldValue(event.name, event.value);
                      handleInputChange(event);
                    }}
                  />)}
                }
              />
            </Grid>
          }
          {hayUbicacionConfirmada &&
            <>
              <Grid item xs={12}>
                <div style={{ margin: 8 }}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel>Ubicación confirmada</InputLabel>
                    <OutlinedInput
                      value={ubicacionConfirmada}
                      disabled={true}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton 
                            edge="end" 
                            onClick={() => 
                              formik.paso1.panel2.setValues({
                                ...formik.paso1.panel2.values,
                                mapUrl: "",
                                ubicacionConfirmada: "",
                                mapEdit: false,
                                lat: 0,
                                lng: 0,
                              })
                            } 
                            disabled={!hayUbicacionConfirmada}
                          >
                            <Edit />
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Ubicación confirmada"
                    />
                  </FormControl>
                </div>
              </Grid>
              
              <Grid item xs={12} md={6}>
                <TextGiz
                  name="direccion"
                  label="Dirección"
                  formik={formik.paso1.panel3}
                  placeholder="Ingrese la dirección de la propiedad"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextGiz
                  name="barrio"
                  label="Barrio"
                  formik={formik.paso1.panel3}
                  placeholder="Ingrese el barrio"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextGiz
                  name="localidad"
                  label="Localidad"
                  formik={formik.paso1.panel3}
                  placeholder="Ingrese la localidad"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextGiz
                  name="partido"
                  label="Partido"
                  formik={formik.paso1.panel3}
                  placeholder="Ingrese el partido"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextGiz
                  name="provincia"
                  label="Provincia"
                  formik={formik.paso1.panel3}
                  placeholder="Ingrese la provincia"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextGiz
                  name="pais"
                  label="Pais"
                  formik={formik.paso1.panel3}
                  placeholder="Ingrese el pais"
                />
              </Grid>
            </>
          }
          
          <Grid item xs={12}>
            {hayUbicacionConfirmada && !mapEdit && mapUrl && 
              <div className={classes.mapUrl} style={{backgroundImage: `url(${mapUrl})`}}></div>
            }
            {!hayUbicacionConfirmada && !mapEdit && !mapUrl && 
              <div className={classes.mapUrl} style={{height: "250px"}}></div>
            }
            {hayUbicacionConfirmada && map.init &&
              <div className={classes.mapUrl} hidden={!mapEdit}>
                <GoogleMapEdit {...{lat,lng, onPositionChange: handleMapChangePosition}}></GoogleMapEdit>
              </div>
            }
          </Grid>
          
          <Grid item xs={12}>
            { hayUbicacionConfirmada && mapUrl && !mapEdit &&
              <Button  variant="contained" color="primary" onClick={handleMapEditClick} >
                Corregir Ubicacion
              </Button>
            }
            { hayUbicacionConfirmada && mapUrl && mapEdit &&
              <Button variant="contained" color="primary" onClick={() => handleMapEditConfirm(lat, lng)} >
                Confirmar Ubicacion
              </Button>
            }
            { hayUbicacionConfirmada && mapUrl && mapEdit &&
              <Button variant="text" color="primary" onClick={handleMapEditCancel} >
                Cancelar
              </Button>
            }
          </Grid>
          
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Mostrar localización:</FormLabel>
              <RadioGroup
                aria-label="gender"
                name="mostrarLocalizacion"
                value={formik.paso1.panel2.mostrarLocalizacion}
                onChange={formik.paso1.panel2.handleChange}
              >
                <FormControlLabel value="EXACTA" control={<Radio />} label="Exacta" />
                <FormControlLabel value="APROXIMADA" control={<Radio />} label="Aproximada" />
              </RadioGroup>
            </FormControl>  
          </Grid>

        </Grid>
      </SimpleAccordion>
    </>
  );
}