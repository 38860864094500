import { Backdrop, CircularProgress } from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { appBarActions, authActions } from '../../../redux/actions';

const _Logout = (props) => {
  let history = useHistory();
  useEffect(() => {
    setTimeout(()=>{
      props.closeMiCuenta();
      props.logout();
      history.replace("/");
      window.location.reload();
    }, 1000);
  }, []);

  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = {
  logout: authActions.logout,
  closeMiCuenta: appBarActions.closeMiCuenta,
}

export const Logout = connect(mapStateToProps, mapDispatchToProps)(_Logout);

export default Logout;