import React, { Fragment } from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '../AppBar';
import useStyles from './style';
import Grid from '@material-ui/core/Grid';
import { Buscador } from '../';


export const Page = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item  xs={12}>
        </Grid>
        <Grid item  xs={12}>
        </Grid>
        <Grid item xs={12} md={1}>
        </Grid>
        <Grid item xs={12} md={10} style={{ height:"800px" }}>
          <Buscador/>
        </Grid>
        <Grid item xs={12} md={1}>
        </Grid>
      </Grid>
    </div>
  );
}