import { authActions } from './';
import { useApiAvisos as api } from '../../app/services/ApiService';
import { avisosConstants as constants } from '../constants';
import { toDispatch } from '../utils';

const { loadToken } = authActions;

const index = () => (dispatch, getState) => {
  dispatch(toDispatch(constants.INDEX_REQUEST));
  api(getState).index()
  .then( response => {
    dispatch(toDispatch(constants.INDEX_SUCCESS, response.data));
  })
  .catch( error => {
    dispatch(toDispatch(constants.INDEX_FAILURE, error));
  })
}

export const avisosActions = {
  index
};