import axios from 'axios';

//const API_URL = "https://localhost:6001/api"; 
//const API_URL = "http://quintuusapi.pablogonzalez.ar/api"; 
const API_URL = "https://quintuus.com/api/v1"; 

export const useApiAuth = () => {
  
  const login = (usuario, password) => {
    return axios.post(`${API_URL}/Account/Login`, {usuario, password});
  }

  const reloadToken = (token) => {
    return axios.post(`${API_URL}/Account/ReLogin`, {}, {headers : {Token : "Bearer " + token}});
  }

  const validateToken = (data) => {
    console.log(data)

    return axios.post(`${API_URL}/Account/ValidateToken`, data);
  }

  return {login, reloadToken, validateToken};

}

const ApiBase = (controller, token) => {
  
  const index = () => {
    return axios.get(`${API_URL}/${controller}`, {headers : {Token : "Bearer " + token}});
  }
  
  const editForm = (id, ) => {
    return axios.get(`${API_URL}/${controller}/EditForm/${id}`, {headers : {Token : "Bearer " + token}});
  }
  
  const editItem = (id, data) => {
    return axios.put(`${API_URL}/${controller}/${id}`, data, {headers : {Token : "Bearer " + token}});
  }
  
  const addForm = () => {
    return axios.get(`${API_URL}/${controller}/CreateForm`, {headers : {Token : "Bearer " + token}});
  }
  
  const addItem = (data) => {
    return axios.post(`${API_URL}/${controller}`, data, {headers : {Token : "Bearer " + token}});
  }
  
  const removeItem = (id) => {
    return axios.delete(`${API_URL}/${controller}/${id}`, {headers : {Token : "Bearer " + token}});
  }
  
  return {index, editForm, editItem, addForm, addItem, removeItem};
}
export const useApiUsuarios = (getState) => ApiBase("Usuarios", getState().auth.token);
export const useApiAvisos = (getState) => ApiBase("Avisos", getState().auth.token);
export const useApiPublicaciones = (getState) => ApiBase("Publicaciones", getState().auth.token);


