/**
 * Entry application component used to compose providers and render Routes.
 * */

 import React from "react";
 import { BrowserRouter } from "react-router-dom";
 import { Routes } from "./app/controllers";
 import { MaterialThemeProvider } from "./app/_core/MaterialThemeProvider";
 import { SnackProvider } from "./app/contexts/Snack";
 import { MuiPickersUtilsProvider } from "@material-ui/pickers";
 import MomentUtils from '@date-io/moment';
 import 'moment/locale/es-mx'; 
 import moment from "moment";
 import { Provider } from "react-redux";
 import store from "./redux/store";
 
 moment.locale("es-mx");
 
 export default function App({ basename }) {
   return (
     
     <Provider store={store}>
       {/*</Provider>*<PersistGate persistor={persistor} loading={<LayoutSplashScreen />
         Add high level `Suspense` in case if was not handled inside the React tree.
         <React.Suspense fallback={<LayoutSplashScreen />
           Override `basename` (e.g: `homepage` in `package.json`) */}
           <BrowserRouter basename={basename}>
             <MaterialThemeProvider>
               <SnackProvider>
                 <MuiPickersUtilsProvider utils={MomentUtils} >
                   <Routes />
                 </MuiPickersUtilsProvider>
               </SnackProvider>
             </MaterialThemeProvider>
           </BrowserRouter>
          {/*</React.Suspense>
      </PersistGate>*/}
     </Provider>
   );
 }
