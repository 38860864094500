import { appBarConstants } from "../constants";

const defaultState = {
  anchorMiCuenta: null,
  showLoginDialog: false
};

const reducer = (state = defaultState, {type, payload}) => {
  switch(type){
    case appBarConstants.OPEN_LOGIN_DIALOG:
      return {
        ...state,
        showLoginDialog: true,
      };
    case appBarConstants.CLOSE_LOGIN_DIALOG:
      return {
        ...state,
        showLoginDialog: false,
      };
    case appBarConstants.OPEN_MI_CUENTA:
      return {
        ...state,
        anchorMiCuenta: payload,
      };
    case appBarConstants.CLOSE_MI_CUENTA:
      return {
        ...state,
        anchorMiCuenta: null,
      };
    default:
      return state;
  }
}

export default reducer;