
export const authConstants = {
    RELOAD_SUCCESS: 'AUTH_RELOAD_SUCCESS',
    RELOAD_FAILURE:  'AUTH_RELOAD_FAILURE',
    RELOAD_EMPTY_TOKEN: 'AUTH_RELOAD_EMPTY_TOKEN',
    LOGIN_REQUEST: 'AUTH_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'AUTH_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'AUTH_LOGIN_FAILURE', 
    LOGOUT: 'AUTH_LOGOUT',
    REGISTER_REQUEST: 'AUTH_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'AUTH_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'AUTH_REGISTER_FAILURE', 
};

export const authTypeConstants = {
  QUINTUUS: 'QUINTUUS',
  GOOGLE: 'GOOGLE',
  FACEBOOK: 'FACEBOOK'
}