import React, { useEffect, useState } from 'react';
import { StepButton, Stepper as MuiStepper, Collapse, useMediaQuery } from '@material-ui/core';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';

export const _Stepper = ({formik, onSubmit = ()=>{}, isFetching = false, tiposCamas = [], stepper = {}, updateItem = () => {}, classes = {}}) => {

  //const {steps, titles, loader, data, updateData} = useTrasladosContext();


  const steps = stepper.pasos;

  const stepsArray = Object.keys(steps).map((key, index) => steps[key]);
  const [panelActivo, setPanelActivo] = useState(stepsArray.map(() => "panel1"));

  let history = useHistory(); 
  let paramStep = useParams().key;
  const activeStepName = paramStep && Object.keys(steps).includes(paramStep.toLowerCase() ) ? paramStep.toLowerCase() : Object.keys(steps)[0];
  const activeStep = steps[activeStepName] ? steps[activeStepName].index : 0;


  const handleChangePanelActivo = (stepIndex, panel) => {
    setPanelActivo(panelActivo.map((value, index) => index === stepIndex ? panel : value));
  }

  useEffect(() => {
    if (!isStepValidToSelect(steps[activeStepName])){
      goToStep(steps[Object.keys(steps)[0]]);
    }
  }, [])
  
  const getActiveStep = () => {
    return steps[activeStepName];
  }

  const handleNext = () => {
    goToStep(Object.values(steps)[activeStep + 1]);
  };

  const handleBack = () => {
    goToStep(Object.values(steps)[activeStep - 1]);
  };

  const isStepValidToSelect = (step) => {
    return step.index===0 || Object.values(steps)[step.index - 1].isCompleted(formik);
  }
  
  const handleStepClick = (step) => {
    if(
      isStepValidToSelect(step)
    ){
      goToStep(step);
    }
  }

  const goToStep = (step) => {
    history.push(step.route);
  }

  return (
    <>
      <div>
        <MuiStepper nonLinear activeStep={activeStep} alternativeLabel className={classes.stepper} >
          {stepsArray.map((item, index) => (
            <Step key={item.title} >
              <StepButton onClick={()=>handleStepClick(item)}>
                <StepLabel  StepIconComponent={(props)=>StepIcon({...props, steps})}>
                  {item.title}
                </StepLabel>
              </StepButton>
            </Step>
          ))}
        </MuiStepper>
        {stepsArray.map((item, index) => 
          <Collapse orientation="vertical" in={index == activeStep} timeout="auto"  unmountOnExit 
            classes={{
              wrapperInner: classes.colapseWrapperInner, // class name, e.g. `classes-nesting-root-x`
            }}
          >
            <Content 
              component={item.content} 
              config = {{
                onBack: handleBack, 
                onNext: handleNext, 
                onSubmit: onSubmit,
                loading: isFetching,
                formik: formik, 
                completed: getActiveStep().isCompleted(formik),
                handleChangePanelActivo: (panel) => handleChangePanelActivo(index, panel),
                panelActivo: panelActivo[index],
                classes
              }} 
            />
          </Collapse>
        )}
      </div>    
      {/*<pre >
        {JSON.stringify(data, null, 2) }
      </pre>*/}
    </>
  );
}

export const Content = (props) => {
  if(props.component === undefined) return <></>;
  return(
    <props.component {...props.config}/>
  )
}


function StepIcon(props) {
  const icon ={content: Object.values(props.steps)[props.icon-1].icon};
  return (
    <div style={{cursor: "pointer", ...(
      props.active ? 
        {
          backgroundColor: "rgb(240, 230, 230)",
          padding: "5px",
          borderRadius: "50%",
          marginTop: "-4px",
        }: 
        {
          padding: "5px",
          borderRadius: "50%",
          marginTop: "-4px",
        }
      )}}>
      <icon.content color={props.active ? "var(--color-gizmedic-active)" : "var(--color-gizmedic)"}/>
    </div>
  );
}


const mapStateToProps = state => ({
  tiposCamas: state.publicaciones.tiposCamas,
  stepper: state.publicaciones.stepper,
});

const mapDispatchToProps = {
}

export const Stepper = connect(mapStateToProps, mapDispatchToProps)(_Stepper);
