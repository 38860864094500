
import {authConstants, authTypeConstants } from "../constants";

const defaultState = {
  remember: false,
  email: "",
  isAuthenticated: false, 
  token: "", 
  name: "", 
  userName: "", 
  role: "", 
  id: "", 
  loaded: false, 
  isFetching: false, 
  isVerificated: true, 
  authType: null,
  photoURL: ""
};

export const reducer = (state = defaultState, {type, payload}) => {
  console.log(payload);
  switch(type){
    case authConstants.RELOAD_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        token: payload.token, 
        name: payload.name, 
        userName: payload.userName, 
        role: payload.role, 
        id: payload.id,
        isVerificated: payload.isVerificated,
        email: payload.email,
        remember: payload.remember,
        isFetching: false,
        loaded: true,
        photoURL: payload.fotoURL
      };
    case authConstants.RELOAD_FAILURE:
      return {
        ...defaultState,
        loaded: true
      };      
    case authConstants.RELOAD_EMPTY_TOKEN:
      return {
        ...defaultState,
        loaded: true
      };  
    case authConstants.LOGIN_REQUEST: 
      return {
        ...state,
        isFetching: true,
      };
    case authConstants.LOGIN_SUCCESS: 
      return {
        ...state,
        isAuthenticated: true,
        token: payload.token, 
        name: payload.name, 
        userName: payload.userName, 
        role: payload.role, 
        id: payload.id,
        isFetching: false,
        isVerificated: payload.isVerificated,
        email: payload.email,
        remember: payload.remember,
        authType: payload.authType,
        photoURL: payload.fotoURL
      }
    case authConstants.LOGIN_FAILURE: 
      return {
        ...state,
        isFetching: false,
      }
  
    case authConstants.LOGOUT: 
      return defaultState;
  
    case authConstants.REGISTER_REQUEST: 
      return {
        ...state,
        isFetching: true,
      };
  
    case authConstants.REGISTER_SUCCESS:  
      return {
        ...state,
        isAuthenticated: true,
        token: payload.token, 
        name: payload.name, 
        userName: payload.userName, 
        role: payload.role, 
        id: payload.id,
        isFetching: false,
        isVerificated: false,
        email: payload.email,
        remember: payload.remember,
        authType: authTypeConstants.QUINTUUS,
        photoURL: payload.fotoURL,
      }
  
    case authConstants.REGISTER_FAILURE: 
      return {
        ...state,
        isFetching: false,
      }

    default:
      return state;
  }
}

export default reducer;