import React, { useEffect, useState } from "react"
import { Button, TextField, Typography } from "@material-ui/core"
import { TakePhotoGiz } from "./TakePhotoGiz";
import { useCamera } from '@capacitor-community/react-hooks/camera';
import { CameraResultType } from '@capacitor/core';
import { useCallback } from 'react';


export const UploadFotoGiz = ({name, error, helperText, label, placeholder, disabled, style = {}, onFileConfirm}) => {

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileCancel = (event) => {
    setSelectedFile(null);
  }
    // On file select (from the pop up) 
  const onFileChange = (event) => { 
    if(event.target.files.length > 0) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.addEventListener('load', (event) => {
        setSelectedFile(
          {
            id:0,
            src: event.target.result,
            name: file.name,
          } 
        );
        event.target.value = [ ];
      });
      reader.readAsDataURL(file) ;
    }
  }; 

  const handleFileConfirm = () => {
    onFileConfirm(selectedFile);
    setSelectedFile(null);
  }
  
  const { getPhoto } = useCamera();

  const triggerCamera = useCallback(async () => {
    getPhoto({
        quality: 100,
        allowEditing: false,
        resultType: CameraResultType.DataUrl
      }).then((x)=>{
        onFileConfirm({
          id: 0,
          src: x.dataUrl,
          name: "Foto",
        });
      })
  }, [getPhoto]);

  return (

 
  <Button color="primary" variant="outlined" style={{width: "100%", height: "80px", borderRadius: "0px", border: "0px solid black"}} onClick={triggerCamera}>Agregar Foto
        {/* 
          <input type="file" name="imageUpload" id={name} style={{display: "none"}} onChange={onFileChange} /> 
          <Button  color="primary" variant="contained" disabled={Boolean(selectedFile)}>
            <label for={name} style={{cursor:"pointer"}} >Selecciona el archivo</label>
          </Button>
          {selectedFile && 
            <>
              <Button  color="primary" variant="contained" disabled={!selectedFile} onClick={handleFileCancel}>
                Cancelar
              </Button>
              <Button  color="primary" variant="contained" disabled={!selectedFile} onClick={handleFileConfirm}>
                Confirmar
              </Button>
              <img
                width="120px"
                src={selectedFile.src}
                alt={selectedFile.name}
              />
            </>
          }    
        */}
    </Button>

  )
}

