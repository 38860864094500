import { toDispatch } from '../utils';
import { appBarConstants as constants } from '../constants';

const openLoginDialog = () => (dispatch, getState) => {
  dispatch(toDispatch(constants.OPEN_LOGIN_DIALOG));
}

const closeLoginDialog = () => (dispatch, getState) => {
  dispatch(toDispatch(constants.CLOSE_LOGIN_DIALOG));
}

const openMiCuenta = (anchor) => (dispatch, getState) => {
  dispatch(toDispatch(constants.OPEN_MI_CUENTA, anchor));
}

const closeMiCuenta = () => (dispatch, getState) => {
  dispatch(toDispatch(constants.CLOSE_MI_CUENTA));
}

export const appBarActions = {
  openLoginDialog,
  closeLoginDialog,
  openMiCuenta,
  closeMiCuenta,
};