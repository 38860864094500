import { useApiPublicaciones as api} from '../../app/services/ApiService';
import { publicacionesConstants as constants } from '../constants';
import { toDispatch, baseAction } from '../utils';



const updateItem = (item) => (dispatch, getState) => {
  return new Promise((resolve) => {
    dispatch(toDispatch(constants.UPDATE_ITEM.SUCCESS, item));
    resolve();
  })
}

const index = () => (dispatch, getState) => {
  return baseAction({
    fn: api(getState).index,
    ...constants.INDEX,
    dispatch,
    getState
  });
}

const basicIndex = () => (dispatch, getState) => {
  return baseAction({
    fn: api(getState).basicIndex,
    ...constants.INDEX,
    dispatch,
    getState
  });
}

const getItem = (id) => (dispatch, getState) => {
  return baseAction({
    fn: () => api(getState).getItem(id),
    ...constants.GET_ITEM,
    dispatch,
    getState
  });
}

const addForm = () => (dispatch, getState) => {
  return baseAction({
    fn: api(getState).addForm,
    ...constants.ADD_FORM,
    dispatch,
    getState
  });
}

const editForm = (id) => (dispatch, getState) => {
  return baseAction({
    fn: () => api(getState).editForm(id),
    ...constants.EDIT_FORM,
    dispatch,
    getState
  });
}

const addItem = (data) => (dispatch, getState) => {
  return baseAction({
    fn: () => api(getState).addItem(data),
    ...constants.ADD_ITEM,
    dispatch,
    getState
  });
}

const editItem = (id, data) => (dispatch, getState) => {
  return baseAction({
    fn: () => api(getState).editItem(id, data),
    ...constants.EDIT_ITEM,
    dispatch,
    getState
  });
}

const removeItem = (id) => (dispatch, getState) => {
  return baseAction({
    fn: () => api(getState).removeItem(id),
    ...constants.REMOVE_ITEM,
    dispatch,
    getState
  });
}

export const actions = {
  index,
  getItem,
  addItem,
  editItem,
  addForm,
  editForm,
  removeItem,
  basicIndex,
  updateItem
};