import React from 'react';
import useStyles from './style.js'

//import Autocomplete from '../autocomplete';
import MenuIcon from '@material-ui/icons/Menu';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { LoginDialog, Popover } from '../';
import { Button, Link, Typography, IconButton, Toolbar, AppBar, Avatar} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import logo from "../../assets/img/logo/logo-appbar.png";

import ListItemIcon from '@material-ui/core/ListItemIcon';

import {EnvelopeOpenTextIcon, StarIcon, NewspaperIcon, TrashIcon, UserCircleIcon, IdCardIcon, SignOutAltIcon} from '../../helpers/icons/';
import { Person } from '@material-ui/icons';

function ListItemLink(props) {
  return (
    <ListItem button onClick={props.onClick} className={props.className} component={RouterLink}  to={props.href}>
        {props.children}
    </ListItem>
  );
}

const Page = ({
        auth,
        anchorMiCuenta,
        handleMiCuentaClose,
        handleMiCuentaOpen,
        handleLoginDialogOpen,
        handleLoginDialogClose,
        showLoginDialog,
        handleLogoutClick
    }) => {
      const classes = useStyles();

    return (
        <div className={classes.root}>
          <AppBar color="default">
            <Toolbar>
              {/*
                <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                  <MenuIcon />
                </IconButton>              
              */}
              <Typography variant="h6" color="inherit" className={classes.title} noWrap>
                <img width="138px" height="26px" className={classes.logo} src={logo} alt="Logo" />
              </Typography>

              <Link align="center" className={classes.buscarPropiedades} component={RouterLink} to="/" > 
                <Button className={classes.publicar}>Buscar Quintas</Button>
              </Link>

              <Link align="center" className={classes.buscarPropiedades} component={RouterLink} to={!auth.isAuthenticated ? "/Login" : "/Dashboard/Publicar"} > 
                <Button className={classes.publicar}>Publicar</Button>
              </Link>

              <Button className={classes.noticias}>Noticias</Button>
              <div className={classes.separador}></div>
                {auth && auth.isAuthenticated && !auth.photoURL &&
                  <IconButton color="primary" id="pop-mi-cuenta" onClick={handleMiCuentaOpen}>
                    <Avatar> <Person/> </Avatar>
                  </IconButton>
                }
                {auth && auth.isAuthenticated && auth.photoURL &&
                  <IconButton color="primary" id="pop-mi-cuenta" onClick={handleMiCuentaOpen}>
                    <Avatar alt="foto" src={auth.photoURL} />
                  </IconButton>
                }
                {auth && !auth.isAuthenticated && 
                  <Button color="primary" id="pop-mi-cuenta" onClick={handleMiCuentaOpen}>
                    <>Mi Cuenta</>
                  </Button>
                }
            </Toolbar>
          </AppBar>
          <Popover anchorEl={anchorMiCuenta} handleClose={handleMiCuentaClose} id="pop-mi-cuenta" >
            {!auth.isAuthenticated &&
              <List component="nav" aria-label="secondary mailbox folders">
                <ListItemLink href="/Login" onClick={handleLoginDialogOpen}>
                  <ListItemText primary="Ingresar" className={classes.ingresar} />
                </ListItemLink>
                <ListItem>
                  <ListItemText primary="" secondary="¿No estás registrado?" />
                </ListItem>
                <ListItemLink href="/Register">
                  <ListItemText primary="Registrate ahora" className={classes.ingresar} />
                </ListItemLink>
              </List>
            }
            {auth.isAuthenticated &&
              <List component="nav" aria-label="secondary mailbox folders">
                <ListItemLink href="/Dashboard/Mensajes">
                  <ListItemIcon>
                    <EnvelopeOpenTextIcon primaryColor="#1f5772" secondaryColor="#2aaede" width="20" height="20" />
                  </ListItemIcon>
                  <ListItemText primary="Bandeja de mensajes" />
                </ListItemLink>


                <ListItemLink href="/Dashboard/Avisos">
                  <ListItemIcon>
                    <NewspaperIcon primaryColor="#1f5772" secondaryColor="#2aaede" width="20" height="20" />
                  </ListItemIcon>                  
                  <ListItemText primary="Mis avisos" />
                </ListItemLink>
                <ListItemLink href="/Dashboard/Favoritos">
                  <ListItemIcon>
                    <StarIcon primaryColor="#1f5772" secondaryColor="#2aaede" width="20" height="20" />
                  </ListItemIcon>                  
                  <ListItemText primary="Favoritos" />
                </ListItemLink>
                <ListItemLink href="/Dashboard/Descartados">
                  <ListItemIcon>
                    <TrashIcon primaryColor="#1f5772" secondaryColor="#2aaede" width="20" height="20" />
                  </ListItemIcon>                  
                  <ListItemText primary="Descartados" />
                </ListItemLink>
                <ListItemLink href="/Dashboard/Cuenta">
                  <ListItemIcon>
                    <UserCircleIcon primaryColor="#1f5772" secondaryColor="#2aaede" width="20" height="20" />
                  </ListItemIcon>                  
                  <ListItemText primary="Mis datos"  />
                </ListItemLink>
                <ListItemLink href="/Dashboard/Anunciante">
                  <ListItemIcon>
                    <IdCardIcon primaryColor="#1f5772" secondaryColor="#2aaede" width="20" height="20" />
                  </ListItemIcon>                  
                  <ListItemText primary="Datos del anunciante" />
                </ListItemLink>
                <ListItemLink href="/Logout">
                  <ListItemIcon>
                    <SignOutAltIcon primaryColor="#1f5772" secondaryColor="#2aaede" width="20" height="20" />
                  </ListItemIcon>                  
                  <ListItemText primary="Salir"  />
                </ListItemLink>
              </List>
            }

          </Popover>
          <LoginDialog handleClose={handleLoginDialogClose} open={showLoginDialog} />
        </div>
    );
}

export default Page;