import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: theme.spacing(3),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0), 
      margin: theme.spacing(1)
    }
  },

}));

export default useStyles;