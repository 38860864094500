import React, {Component} from "react"
import ReactGoogleMapLoader from "react-google-maps-loader"
import ReactGooglePlacesSuggest from "../../../../react-google-places-suggest/lib";
import { TextGiz } from "../../../helpers"

const MY_API_KEY = "AIzaSyCXqMUH-2r3_AJm12HkGE8Mu9yfwqZv2PU" // fake

const getFromResultComponent = (type, result) => {
  const res = result.address_components.filter((component) => { return component.types.some((t) => {return t == type;}) });
  return res.length > 0 ? res[0].long_name : "";
}

const predictionToObject = (result) => {
  return {
    formatted_address: result.formatted_address,
    street_number: getFromResultComponent("street_number", result),
    route: getFromResultComponent("route", result),
    locality: getFromResultComponent("locality", result),
    administrative_area_level_2: getFromResultComponent("administrative_area_level_2", result),
    administrative_area_level_1: getFromResultComponent("administrative_area_level_1", result),
    country: getFromResultComponent("country", result),
    postal_code: getFromResultComponent("postal_code", result),
    postal_code_suffix: getFromResultComponent("postal_code_suffix", result),
    lat: result.geometry.location.lat(),
    lng: result.geometry.location.lng(),
  };
}

export default class GoogleSuggest extends React.Component {
    state = {
        search: "",
        timer: null,
        value: this.props.value,
    }

    handleInputChange = e => {
      this.state.timer !== null && clearTimeout(this.state.timer);
      const newTimer = setTimeout( (_x, _e) => _x.setState({search: _e.target.value, value: _e.target.value, timer: null}), 1000, this, e );
      this.setState({ 
        ...this.state,
        value: e.target.value,
        timer: newTimer,
      });
    }

    handleSelectSuggest = (geocodedPrediction, originalPrediction) => {
        this.setState({
            ...this.state,
            search: "",
            //value: geocodedPrediction.formatted_address,
            timer: 0,
        })
        this.props.setValue && this.props.setValue(geocodedPrediction.formatted_address);
        this.props.onPrediction && this.props.onPrediction(predictionToObject(geocodedPrediction));
    }

    handleNoResult = () => {
        console.log("No results for ", this.state.search)
    }

    handleStatusUpdate = status => {
        console.log(status)
    }

    render() {
        const {search, value} = this.state
        return (
            <ReactGoogleMapLoader
                params={{
                    key: MY_API_KEY,
                    libraries: "places",

                }}
                render={googleMaps =>
                    googleMaps && (
                        <ReactGooglePlacesSuggest
                          googleMaps={googleMaps}
                          autocompletionRequest={{
                              input: search,
                              // Optional options
                              // https://developers.google.com/maps/documentation/javascript/reference?hl=fr#AutocompletionRequest
                          }}
                          // Optional props
                          onNoResult={this.handleNoResult}
                          onSelectSuggest={this.handleSelectSuggest}
                          onStatusUpdate={this.handleStatusUpdate}
                          textNoResults="My custom no results text" // null or "" if you want to disable the no results item
                          customRender={prediction => (
                              <div className="customWrapper">
                                  {prediction
                                      ? prediction.description
                                      : "My custom no results text"}
                              </div>
                          )}
                        >
                          {this.props.input(this.handleInputChange) }
                        </ReactGooglePlacesSuggest>
                    )
                }
            />
        )
    }
}