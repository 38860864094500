import React from 'react';
import { Button, Grid, Link, Typography, CircularProgress, Backdrop  } from '@material-ui/core';
import { ContentWrapper } from '../../helpers/ContentWrapper';
import { TextGiz } from '../../helpers/TextGiz';
import { SwitchGiz } from '../../helpers/SwitchGiz';
import useStyles from './style';

import { Link as RouterLink } from 'react-router-dom';

const Page = ({title, subTitle, formik, handleControlChange, handleIngresarConGoogleClick, handleIngresarConFacebookClick}) => {
  const classes = useStyles();

  const handleChange = (event) => {
      handleControlChange[event.target.name] && handleControlChange[event.target.name](event.target.value); 
      formik.handleChange(event);
  }

  return (
     
        <ContentWrapper title={title} subTitle={subTitle}>
          <form onSubmit={formik.handleSubmit} >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Button variant="contained" color="primary" startIcon={<FacebookIcon />} className={classes.buttonFacebook} onClick={handleIngresarConFacebookClick}>
                  Iniciar Sesión con Facebook
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" color="primary" startIcon={<GoogleIcon />} className={classes.buttonGoogle} onClick={handleIngresarConGoogleClick}>
                  Iniciar Sesión con Google
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Typography align="center">o inicia sesón con</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextGiz
                  name="usuario"
                  label="Usuario o email"
                  formik={formik}
                  onChange={handleChange}
                  placeholder="Ingrese su nombre de usuario o correo electrónico"
                />
              </Grid>
              <Grid item xs={12}>
                <TextGiz
                  type="password"
                  name="password"
                  label="Password"
                  formik={formik}
                  onChange={handleChange}
                  placeholder="Ingrese su password"
                />
              </Grid>
              <Grid item xs={6}>
                <SwitchGiz
                  type="recordar"
                  name="recordar"
                  label="No cerrar sesión"
                  formik={formik}
                  onChange={handleChange}
                  placeholder="No cerrar sesión"
                />
              </Grid>
              <Grid item xs={6}>
                <Link align="center" className={classes.forgotPassword} component={RouterLink} color="secondary" to="/ForgotPassword" > 
                  <Typography style={{margin: "0px", paddingTop: "16px"}}>Olvidaste te password?</Typography>
                </Link>
              </Grid>
               <Grid item xs={12}>
                <Button 
                  type="submit" 
                  variant="contained" 
                  color="secondary" 
                  disabled={formik.isSubmitting} 
                  className={classes.buttonIniciarSesion}
                >
                  Iniciar Sesión
                </Button>
                  
                </Grid>
            </Grid>
           
          </form>

          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={formik.isSubmitting}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </ContentWrapper>

  );
};

const GoogleIcon = props => {
  const { className } = props;
  return (
      <svg
          className={className}
          width="26"
          height="26"
          viewBox="0 0 26 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
      >
          <path
              d="M13.2641 10.7107V15.678H20.485C19.5407 18.6875 16.9747 20.8416 13.2641 20.8416C8.84539 20.8416 5.26347 17.3303 5.26347 13C5.26347 8.6697 8.84539 5.1584 13.2641 5.1584C15.2507 5.1584 17.0648 5.8721 18.4639 7.0473L22.1957 3.3891C19.8391 1.2844 16.7041 0 13.2641 0C5.93848 0 0 5.8201 0 13C0 20.1799 5.93848 26 13.2641 26C24.3984 26 26.8558 15.795 25.7643 10.7276L13.2641 10.7107Z"
              fill="white"
          />
      </svg>
  );
};

const FacebookIcon = props => {
  const { className } = props;
  return (
      <svg
          className={className}
          width="26"
          height="26"
          viewBox="0 0 300 300"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M172,90.9V67.1c0-11.5,7.6-14.2,13.1-14.2h33.2V2.2L172.6,2c-50.7,0-62.3,37.8-62.3,62v26.8H80.9V150h29.6v148
          h59.2V150h49.5l-0.1-59.1C219.1,90.9,172,90.9,172,90.9z" fill="white"/>
      </svg>
  );
};

export default Page;