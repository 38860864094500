import React from "react"
import { FormControl, InputLabel, makeStyles, MenuItem, OutlinedInput, Select } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export const SelectGiz = ({name, label, formik, onChange, items,  placeholder = "Seleccione...", labelWidth = 80, children = null, multiple, renderValue }) => {
  
  const classes = useStyles();

  return (

    <FormControl fullWidth variant="outlined" className={classes.formControl} >
      <InputLabel htmlFor={`outlined-${name}-simple`}>
        {label}
      </InputLabel>
      <Select
        name={name}
        id={name}
        value={formik.values[name]}
        onChange={onChange}
        input={<OutlinedInput labelWidth={labelWidth} />}
        error={formik.touched[name] && Boolean(formik.errors[name])}
        multiple={multiple}
        renderValue={renderValue}
      >
        {placeholder &&
          <MenuItem value="">
            <em>{placeholder}</em>
          </MenuItem>      
        }
        {items ? items.map(item => <MenuItem value={item.value}>{item.text} {!item.active && <span> (inactivo)</span>}</MenuItem>) : children} 
      </Select>
      {formik.touched[name] && formik.errors[name] &&
        <p style={{color: "#f018a6", fontSize: "0.75rem", marginTop: "3px", marginRight: "14px", marginLeft: "14px"}} class="MuiFormHelperText-root MuiFormHelperText-contained Mui-error" id={`${name}-helper-text`}>{formik.errors[name]}</p>              
      }
    </FormControl>
  )

}