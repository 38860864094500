import React, { useEffect, useState } from 'react';
import Page from './page';
import { FormikProvider, useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { connect } from "react-redux";
import { authActions, appBarActions } from "../../../redux/actions";
import { useSnackContext } from '../../contexts/Snack';



const _Login = ({redirectUrl, auth, login, closeLoginDialog, loginWithGoogle, loginWithFacebook}) => {
  
  let history = useHistory();
  const {openSuccess, openError} = useSnackContext();
  const [ data, setData ] = useState({});

  //const [user, setUser] = useState(undefined)
  //useEffect(() => {
  //  onAuthStateChanged(setUser)
  //openError("pepepepep hfkjghfdkjgfhdk ");
    
  //}, [auth.isAuthenticated])

  const handleIngresarConGoogleClick = () => {
    handleIngresarCon(loginWithGoogle);
  }

  const handleIngresarConFacebookClick = () => {
    handleIngresarCon(loginWithFacebook);
  }

  const handleIngresarCon = (fn) => {
    formik.setSubmitting(true)
    fn().then((response) => {
      closeLoginDialog();
      formik.setSubmitting(false);
      redirectUrl && history.push(redirectUrl);
      openSuccess("Login correcto");
    }).catch((error) => {
      formik.setSubmitting(false);
      openError("Error en el login");
    });
  }

  const validationSchema = yup.object({
    usuario: yup
      .string('Ingrese su usuario o email')
      .required('El usuario o email es requerido')
      .max(255, "Puede ingresar hasta 255 caracteres"),
    password: yup
      .string("Ingrese el password")
      .required("El password es requerido")
      .max(20, "Puede ingresar hasta 20 caracteres"),
    recordar: yup
      .bool()
  });

  useEffect(()=>{
    formik.setSubmitting(auth.isFetching);
  },[auth.isFetching])

  const handleControlChange = {
  }
  
  const formik = useFormik({
    initialValues: {
      usuario: '',
      password: '',
      recordar: true,
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      
      formik.setSubmitting(true);
      login(values)
        .then((response)=>{
          closeLoginDialog();
          formik.setSubmitting(false);
          openSuccess("Login correcto");
          redirectUrl && history.push(redirectUrl);
        })
        .catch((error)=>{
          formik.setSubmitting(false);
          openError("Error en el login");
        });
    },
  });

  return (
      <Page title="Iniciar sesión" subTitle="" {...{formik, data, handleControlChange, handleIngresarConGoogleClick, handleIngresarConFacebookClick}} />
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  };
}

const mapDispatchToProps = {
  login: authActions.login,
  loginWithGoogle: authActions.loginWithGoogle,
  loginWithFacebook: authActions.loginWithFacebook,
  closeLoginDialog: appBarActions.closeLoginDialog,

}

export const Login = connect(mapStateToProps, mapDispatchToProps)(_Login);

export default Login;