import React from 'react';
import { Collapse } from '@material-ui/core';
import { SwitchGiz} from './SwitchGiz';
import { WrapperComponent } from './WrapperComponent';

export const SimpleCheck = ({label, name, formik, children, wrapper = true}) => {

  const _wrapper = wrapper ? WrapperComponent : "div";

  return (
    <_wrapper>
      <SwitchGiz {...{label, name, formik}} />
      {children && 
        <Collapse  in={formik.values[name]} timeout="auto" unmountOnExit style={{marginLeft:"16px"}}>
          {children}
        </Collapse>
      }
    </_wrapper>
  )
}
