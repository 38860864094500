import React from 'react';
import useStyles from './style';
import { Login } from '../';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

const Page = ({open, handleClose, title}) => {
  const classes = useStyles();
  return (
      <Dialog onClose={handleClose} open={open}>
        {title && <DialogTitle>{title}</DialogTitle>}
        <Login redirectUrl="/Dashboard/Avisos"/>
      </Dialog>
  );
};

export default Page;