import React from "react";
import BaseIcon from "./_BaseIcon";

export const BullhornIcon = ({ className, primaryColor, secondaryColor, width = "60", height = "60" }) => {
  return (
      <BaseIcon 
          className={className}
          width={width}
          height={height}
          viewBox="0 0 576 512"
      >
        <path fill={secondaryColor} d="M544 448c0 9.22-7.08 32-32 32a32 32 0 0 1-20-7l-85-68a242.82 242.82 0 0 0-119-50.79V125.84a242.86 242.86 0 0 0 119-50.79L492 7a31.93 31.93 0 0 1 20-7c25 0 32 23.26 32 32z"></path>
        <path fill={primaryColor} d="M544 184.88v110.24a63.47 63.47 0 0 0 0-110.24zM0 192v96a64 64 0 0 0 64 64h33.7a243 243 0 0 0-2.18 32 253.32 253.32 0 0 0 25.56 110.94c5.19 10.69 16.52 17.06 28.4 17.06h74.28c26.05 0 41.69-29.84 25.9-50.56A127.35 127.35 0 0 1 223.51 384a121 121 0 0 1 4.41-32H256V128H64a64 64 0 0 0-64 64z"></path>
      </BaseIcon>
  );
};
