import React, { useEffect } from 'react';
import {
  Grid,
  Button,
  Card,
  CardContent,
  Tab,
  Tabs,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { TextNumberGiz, TextGiz, RadioGiz, SimpleAccordion, SimpleCheck, SimpleRadio } from '../../../helpers';
import { Dormitorio } from './dormitorio';
import { connect } from 'react-redux';
import { getCapacidad, getCantidadCamas } from "./dormitorio"
import { usePrevious } from '../../../_core/utils';
import { SimpleActionButton } from '../../../helpers/SimpleActionButton';

export const _Paso2 = ({onBack, onNext, completed, formik, classes = {}, handleChangePanelActivo, panelActivo, tiposCamas}) => {

  const elevation = 1;

  const {
    modalidad, 
    cantidadDormitorios, 
    dormitorios, 
    espaciosCompartidos, 
  } = {...formik.paso2.panel1.values, ...formik.paso2.panel2.values};
  
  const prevData = usePrevious({
    modalidad, 
    cantidadDormitorios, 
    dormitorios, 
    espaciosCompartidos,
  })

  const modalidadOptions = [
    {value: "0", label:""},
    {value: "1", label: "Alojamiento por estadías"},
    {value: "2", label: "Alquiler diario para eventos"},
  ];
  
  const checkOptions = [
    {value: "0", label:""},
    {value: "1", label: "Sí"},
    {value: "2", label: "No"},
    {value: "3", label: "Preguntar"},
  ];

  useEffect(()=>{
    formik.paso2.panel2.submitCount === 0 && actualizarCapacidadPropiedad();
  }, [])

  useEffect(()=>{
    if(prevData){
      actualizarDormitorios();
    }
  }, [dormitorios, espaciosCompartidos, cantidadDormitorios])

  useEffect(()=>{
    modalidad !== 0 && modalidad !== "" && activarPanel("panel2");
  }, [modalidad])

  const actualizarDormitorios = () => {
    while(dormitorios.length < cantidadDormitorios){
      dormitorios.push(nuevoLugar(dormitorios.length));
    }
    while(dormitorios.length > cantidadDormitorios){
      dormitorios.pop();
    }
    formik.paso2.panel2.setValues({
      ...formik.paso2.panel2.values,
      dormitorios: dormitorios,
      ...capacidadPropiedad(),
    });
  }


  const actualizarCapacidadPropiedad = () => {

    formik.paso2.panel2.setValues({
      ...formik.paso2.panel2.values,
      ...capacidadPropiedad(),
    })

  }

  const capacidadPropiedad = () => {
    const lugares = [...espaciosCompartidos, ...dormitorios];
    const cantPersonas = lugares.reduce((a, c) => a + getCapacidad(c.camas), 0);
    const cantCamas = lugares.reduce((a, c) => a + getCantidadCamas(c.camas), 0);
    return {
      cantidadPersonas: cantPersonas,
      cantidadCamasHuespedes: cantCamas,
      cantidadCamasHuespedesDisabled: cantCamas > 0,
    };
  }

  const nuevoLugar = (length) => {
    /*if(length == 0){
      return  {camas:[...tiposCamas.map((x) => x.nombre == "doble" ? {...x, cantidad: 1} : x )]}
    }
    if(length == 1){
      return  {camas:[...tiposCamas.map((x) => x.nombre == "simple" ? {...x, cantidad: 2} : x )]}
    }
    if(length == 2){
      return  {camas:[...tiposCamas.map((x) => x.nombre == "simple" ? {...x, cantidad: 1} : x )]}
    }*/
    return {camas:[...tiposCamas]}
  }

  const handleDormitorioChange = (index, camas) => {
    formik.paso2.panel2.setFieldValue("dormitorios", dormitorios.map((x, i) => i === index ? {...x, camas} : x));
  }

  const handleEspacioCompartidoChange = (index, camas) => {
    formik.paso2.panel2.setFieldValue("espaciosCompartidos", espaciosCompartidos.map((x, i) => i === index ? {...x, camas} : x));
  }

  const activarPanel = (panel) => {
    handleChangePanelActivo(panel);
  }

  const handlePanel1_Volver = () => {
    onBack();
  }

  const handlePanel1_Siguiente = () => {
    formik.paso2.panel1.submitForm().then(()=>{
      activarPanel('panel2');
    })
  }

  const handlePanel2_Volver = () => {
    activarPanel('panel1');
  }

  const handlePanel2_Siguiente = () => {
    formik.paso2.panel2.submitForm().then(()=>{
      activarPanel('panel3_1');
    })
  }

  const handlePanel3_1_Volver = () => {
    activarPanel('panel2');
  }

  const handlePanel3_1_Siguiente = () => {
    formik.paso2.panel3_1.submitForm().then(()=>{
      activarPanel('panel3_2');
    })
  }

  const handlePanel3_1_OmitirOpcionales = () => {
    formik.paso2.panel3_1.submitForm().then(()=>{
      onNext();
    })
  }

  const handlePanel3_2_Volver = () => {
    activarPanel('panel3_1');
  }

  const handlePanel3_2_Siguiente = () => {
    formik.paso2.panel3_2.submitForm().then(()=>{
      activarPanel('panel3_3');
    })
  }

  const handlePanel3_3_Volver = () => {
    activarPanel('panel3_2');
  }

  const handlePanel3_3_Siguiente = () => {
    formik.paso2.panel3_3.submitForm().then(()=>{
      activarPanel('panel3_4');
    })
  }

  const handlePanel3_4_Volver = () => {
    activarPanel('panel3_3');
  }

  const handlePanel3_4_Siguiente = () => {
    formik.paso2.panel3_4.submitForm().then(()=>{
      onNext();
    })
  }

  const handlePanel7_Volver = () => {
    activarPanel('panel3_4');
  }

  const handlePanel7_Siguiente = () => {
    formik.paso2.panel7.submitForm().then(()=>{
      onNext();
    })
  }

  return (
    <>

      <SimpleAccordion title="Modalidad de locación" subTitle={modalidadOptions[modalidad === "" ? 0 : modalidad].label} name="panel1" activePanel={panelActivo}
        actions={[
          <SimpleActionButton label="Volver" variant="text" onClick={handlePanel1_Volver} />
        ]}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <RadioGiz 
              label="Seleccione la modalidad de la locación"
              name="modalidad"
              formik={formik.paso2.panel1}
              options={modalidadOptions}
            />
          </Grid>
        </Grid>
      </SimpleAccordion>

      <SimpleAccordion title="Distribución de espacios" subTitle="" name="panel2" activePanel={panelActivo}
        actions={[
          <SimpleActionButton label="Volver" variant="text" onClick={handlePanel2_Volver} />,
          <SimpleActionButton label="Siguiente" onClick={handlePanel2_Siguiente}  disabled={!formik.paso2.panel2.isValid}/>,
        ]}
      >
        <Grid container spacing={2}>
          {modalidad == 1 && //modalidad hospedaje
            <>
              <Grid item xs={12}>
                <TextNumberGiz 
                  label="Cantidad de dormitorios"
                  name="cantidadDormitorios"
                  formik={formik.paso2.panel2}
                  max={20}
                />
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {dormitorios.map((item, index)=>(
                    <Grid item xs={12} md={4}>
                      <Dormitorio  {...item} index={index} onChange={(camas)=>handleDormitorioChange(index, camas)}/>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  {espaciosCompartidos.map((item, index)=>(
                    <Grid item xs={12} md={4}>
                      <Dormitorio title="Espacios compartidos" showIndex={false} {...item} index={index} onChange={(camas)=>handleEspacioCompartidoChange(index, camas)}/>
                    </Grid>
                  ))}  
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <TextNumberGiz 
                  label="¿Cuántas camas pueden utilizar los huéspedes?"
                  name="cantidadCamasHuespedes"
                  formik={formik.paso2.panel2}
                  max={50}
                  disabled={formik.paso2.panel2.values.cantidadCamasHuespedesDisabled}
                />
              </Grid>
              <Grid item xs={12}>
                <TextNumberGiz 
                  label="¿Cuántas personas pueden hospedarse en tu alojamiento?"
                  name="cantidadPersonas"
                  formik={formik.paso2.panel2}
                  max={50}
                />
              </Grid>
            </>
          }

          {modalidad == 2 && //modalidad eventos
            <>
              <Grid item xs={12}>
                <TextNumberGiz 
                  label="¿Cuántas personas pueden ingresar a su quinta?"
                  name="cantidadPersonas"
                  formik={formik.paso2.panel2}
                  max={1000}
                />
              </Grid>
            </>
          }
          <Grid item xs={12}>
            <TextNumberGiz 
              label="Cantidad de baños"
              name="cantidadBanos"
              formik={formik.paso2.panel2}
              max={10}
            />
          </Grid>
          <Grid item xs={12}>
            <TextNumberGiz 
              label="Cantidad de toilettes"
              name="cantidadToilettes"
              formik={formik.paso2.panel2}
              max={10}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Card square>
              <CardContent>
                <SimpleRadio 
                  label="Acepta grupo de jóvenes"
                  name="aceptaJovenes"
                  formik={formik.paso2.panel2}
                  options={checkOptions}
                  wrapper={false}
                />  
              </CardContent>
            </Card>
          </Grid>   

          <Grid item xs={12} md={6}>
            <Card square>
              <CardContent>
                <SimpleRadio 
                  label="Acepta mascotas"
                  name="aceptaMascotas"
                  formik={formik.paso2.panel2}
                  options={checkOptions}
                  wrapper={false}
                />
              </CardContent>
            </Card>
          </Grid>   

          <Grid item xs={12}>
            <TextGiz 
              label="Superficie construida"
              name="superficieConstruida"
              formik={formik.paso2.panel2}
              type="number"
            />
          </Grid>
          <Grid item xs={12}>
            <TextGiz 
              label="Superficie total"
              name="superficieTotal"
              formik={formik.paso2.panel2}
              type="number"
            />
          </Grid>
        </Grid>
      </SimpleAccordion>

      <SimpleAccordion title="Comodidades y características" subTitle="Favoritos de los huéspedes" name="panel3_1" elevation={1} activePanel={panelActivo}
        actions={[
          <SimpleActionButton label="Cargar más comodidades" variant="text" onClick={handlePanel3_1_Siguiente} />,
          <SimpleActionButton label="Volver" variant="text" onClick={handlePanel3_1_Volver} />,
          <SimpleActionButton label="Siguiente" onClick={handlePanel3_1_OmitirOpcionales} />,
        ]}
      >
        <Grid container spacing={2}>
          <SimpleCheck label="Wifi" name="wifi" formik={formik.paso2.panel3_1} />
          <SimpleCheck label="Aire acondicionado" name="aireAcondicionado" formik={formik.paso2.panel3_1}>
            <SimpleCheck label="Frío" name="aireAcondicionado_Frio" formik={formik.paso2.panel3_1} wrapper={false}/>
            <SimpleCheck label="Frío / Calor" name="aireAcondicionado_Frio_Calor" formik={formik.paso2.panel3_1}  wrapper={false}/> 
          </SimpleCheck>
          <SimpleCheck label="Televisor" name="televisor" formik={formik.paso2.panel3_1} >
            <SimpleCheck label="Canales de cable" name="canalesCable" formik={formik.paso2.panel3_1} wrapper={false}/>               
          </SimpleCheck>
          <SimpleCheck label="Pileta" name="pileta" formik={formik.paso2.panel3_1} >
            <SimpleCheck label="Pileta exterior climatizada" name="pileta_ExteriorClimatizada" formik={formik.paso2.panel3_1}  wrapper={false}>  
              <SimpleCheck label="Con cerco" name="pileta_ExteriorClimatizada_ConCerco" formik={formik.paso2.panel3_1}  wrapper={false}/>  
            </SimpleCheck>
            <SimpleCheck label="Pileta exterior no climatizada" name="pileta_ExteriorNoClimatizada" formik={formik.paso2.panel3_1}  wrapper={false}>  
              <SimpleCheck label="Con cerco" name="pileta_ExteriorNoClimatizada_ConCerco" formik={formik.paso2.panel3_1}  wrapper={false}/>  
            </SimpleCheck>
            <SimpleCheck label="Pileta cubierta" name="piletaCubierta" formik={formik.paso2.panel3_1}  wrapper={false}/>  
            <SimpleCheck label="Pileta infantil" name="piletaInfantil" formik={formik.paso2.panel3_1}  wrapper={false}>  
              <SimpleCheck label="Climatizada" name="piletaInfantil_Climatizada" formik={formik.paso2.panel3_1}  wrapper={false}/>  
              <SimpleCheck label="Con cerco" name="piletaInfantil_ConCerco" formik={formik.paso2.panel3_1}  wrapper={false}/>  
            </SimpleCheck>
          </SimpleCheck>
          <SimpleCheck label="Asador" name="asador" formik={formik.paso2.panel3_1} />
          <SimpleCheck label="Parrilla" name="parrilla" formik={formik.paso2.panel3_1} />
          <SimpleCheck label="Garage cubierto" name="garageCubierto" formik={formik.paso2.panel3_1} >
            <TextNumberGiz 
              label="Cantidad"
              name="garageCubierto_Cantidad"
              formik={formik.paso2.panel3_1}
              max={10}
              slim={true}
            />
          </SimpleCheck>
          <SimpleCheck label="Garage Descubierto" name="garageDescubierto" formik={formik.paso2.panel3_1} >
            <TextNumberGiz 
              label="Cantidad"
              name="garageDescubierto_Cantidad"
              formik={formik.paso2.panel3_1}
              slim={true}
            />
          </SimpleCheck>
        </Grid>
      </SimpleAccordion>

      <Dialog open={["panel3_2", "panel3_3", "panel3_4"].includes(panelActivo)} maxWidth="lg">
        <DialogTitle>
          <Tabs value={panelActivo} onChange={(event, value)=>activarPanel(value)} aria-label="basic tabs example">
            <Tab label="Equipamiento" value="panel3_2"/>
            <Tab label="Instalaciones" value="panel3_3" />
            <Tab label="Entretenimiento" value="panel3_4" />
          </Tabs>
        </DialogTitle>
        <DialogContent>
          <div hidden={panelActivo != "panel3_2"} style={{height: "500px", minHeight: "500px"}}>
            <Grid container spacing={2}>
              <SimpleCheck label="Cocina Totalmente Equipada" name="cocinaTotalmenteEquipada" formik={formik.paso2.panel3_2} />
              <SimpleCheck label="Vajillas y cubiertos" name="vajillasCubiertos" formik={formik.paso2.panel3_2} />
              <SimpleCheck label="Heladera con frezzer" name="heladeraConFrezzer" formik={formik.paso2.panel3_2} />
              <SimpleCheck label="Frezzer" name="frezzer" formik={formik.paso2.panel3_2} />
              <SimpleCheck label="Calefaccción central" name="calefacccionCentral" formik={formik.paso2.panel3_2} />
              <SimpleCheck label="Equipo de Música" name="equipoDeMusica" formik={formik.paso2.panel3_2} />
              <SimpleCheck label="Frigobar" name="frigobar" formik={formik.paso2.panel3_2} />
              <SimpleCheck label="Horno Microondas" name="hornoMicroondas" formik={formik.paso2.panel3_2} />
              <SimpleCheck label="Horno Eléctrico" name="hornoElectrico" formik={formik.paso2.panel3_2} />
              <SimpleCheck label="Tostadora" name="tostadora" formik={formik.paso2.panel3_2} />
              <SimpleCheck label="Estufa" name="estufa" formik={formik.paso2.panel3_2} />
              <SimpleCheck label="Lavarropas" name="lavarropas" formik={formik.paso2.panel3_2} />
              <SimpleCheck label="Secarropas" name="secarropas" formik={formik.paso2.panel3_2} />
              <SimpleCheck label="Cafetera" name="cafetera" formik={formik.paso2.panel3_2} />
              <SimpleCheck label="Pava eléctrica" name="pavaElectrica" formik={formik.paso2.panel3_2} />
              <SimpleCheck label="Lavaplatos" name="lavaplatos" formik={formik.paso2.panel3_2} />
              <SimpleCheck label="Secador de pelos" name="secadorDePelos" formik={formik.paso2.panel3_2} />
              <SimpleCheck label="Ventilador de pie" name="ventiladorDePie" formik={formik.paso2.panel3_2} />
              <SimpleCheck label="Ventilador de techo" name="ventiladorDeTecho" formik={formik.paso2.panel3_2} />
              <SimpleCheck label="Plancha / Tabla de planchar" name="plancha_TablaDePlanchar" formik={formik.paso2.panel3_2} />
            </Grid>
          </div>

          <div hidden={panelActivo != "panel3_3"} style={{height: "500px", minHeight: "500px"}}>
            <Grid container spacing={2}>
              <SimpleCheck label="Chimenea" name="chimenea" formik={formik.paso2.panel3_3} />
              <SimpleCheck label="Hogar a leña" name="hogarLena" formik={formik.paso2.panel3_3} />
              <SimpleCheck label="Salamandra" name="salamandra" formik={formik.paso2.panel3_3} />
              <SimpleCheck label="Jacuzzi" name="jacuzzi" formik={formik.paso2.panel3_3} />
              <SimpleCheck label="Sauna" name="sauna" formik={formik.paso2.panel3_3} />
              <SimpleCheck label="Gimnasio" name="gimnasio" formik={formik.paso2.panel3_3} />
              <SimpleCheck label="Plaza de juegos" name="plazaDeJuegos" formik={formik.paso2.panel3_3} />
              <SimpleCheck label="Cancha de tenis" name="canchaDeTenis" formik={formik.paso2.panel3_3} />
              <SimpleCheck label="Cancha de futbol" name="canchaDeFutbol" formik={formik.paso2.panel3_3} />
              <SimpleCheck label="Cancha de voley" name="canchaDeVoley" formik={formik.paso2.panel3_3} />
            </Grid>
          </div>
          
          <div hidden={panelActivo != "panel3_4"} style={{height: "500px", minHeight: "500px"}}>
            <Grid container spacing={2}>
              <SimpleCheck label="Pool" name="pool" formik={formik.paso2.panel3_4} />
              <SimpleCheck label="Tenis de mesa" name="tenisDeMesa" formik={formik.paso2.panel3_4} />
              <SimpleCheck label="Juegos de mesa" name="juegosDeMesa" formik={formik.paso2.panel3_4} />
              <SimpleCheck label="Metegol" name="metegol" formik={formik.paso2.panel3_4} />
              <SimpleCheck label="Bicicletas" name="bicicletas" formik={formik.paso2.panel3_4} />
              <SimpleCheck label="Consola de juegos" name="consolaDeJuegos" formik={formik.paso2.panel3_4} />
            </Grid>
          </div>

        </DialogContent>
        <DialogActions>
          <SimpleActionButton label="Listo" variant="text" onClick={()=>activarPanel("panel3_1")} />
        </DialogActions>
      </Dialog>

{/*
      <SimpleAccordion title="Descripción de la propiedad" subTitle="" name="panel7" activePanel={panelActivo}
        actions={[
          <SimpleActionButton label="Volver" onClick={handlePanel7_Volver} />,
          <SimpleActionButton label="Siguiente" onClick={handlePanel7_Siguiente} />,
        ]}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextGiz
              autoFocus={true}
              multiline={true}
              rows={8}
              name="descripcion"
              label="Descripción"
              formik={formik.paso2.panel7}
              placeholder="Hablanos sobre tu vivienda"
            />
          </Grid>
        </Grid>
      </SimpleAccordion>      
      */}

    </>
  );
}


const mapStateToProps = state => ({
  tiposCamas: state.publicaciones.tiposCamas,
});

const mapDispatchToProps = {
}

export const Paso2 = connect(mapStateToProps, mapDispatchToProps)(_Paso2);
