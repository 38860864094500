import { avisosConstants } from "../constants";

const defaultState ={ 
  isFetching: false, 
  list: [],
};

const reducer = (state = defaultState, {type, payload}) => {
  switch(type){
    case avisosConstants.INDEX_REQUEST: 
      return {
        isFetching: true,
        list: [],
      };
    case avisosConstants.INDEX_SUCCESS: 
      return {
        isFetching: false,
        list: payload,
      };
    case avisosConstants.INDEX_FAILURE: 
      return {
        isFetching: false,
        list: [],
      };
    default:
      return state;
  }
}

export default reducer;