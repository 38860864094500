import React, {Component} from "react"
import ReactGoogleMapLoader from "react-google-maps-loader"
import ReactGooglePlacesSuggest from "../../../../react-google-places-suggest/lib";
import { TextGiz } from "../../../helpers"

const MY_API_KEY = "AIzaSyCXqMUH-2r3_AJm12HkGE8Mu9yfwqZv2PU" // fake


export default class GoogleMapEdit extends React.Component {


  render() {
    const {lat, lng, text, onPositionChange} = this.props
    return (
      <ReactGoogleMapLoader
          params={{
              key: MY_API_KEY,
              libraries: "places",

          }}
          render={googleMap =>
              googleMap && (
                  <GoogleMap {...{lat, lng, text, googleMap, onPositionChange}} />
              )
          }
      />
    )
  }

}


class GoogleMap extends React.Component {

  state = {  
    lat: this.props.lat,
    lng: this.props.lng,
    text: this.props.text,
  }

  componentDidUpdate(prevProps, prevState) {
    console.log({prevProps, prevState, props: this.props, state: this.state})

    if ( this.hasMap() && this.props && prevProps && (prevProps.lat !== this.props.lat || prevProps.lng !== this.props.lng) ) {

      const location = {lat: parseFloat(this.props.lat), lng: parseFloat(this.props.lng)}
      console.log(location);
      this.setState({
        ...this.state,
        ...location,
      })
      this.marker.setPosition(location);
      this.googleMap.setCenter(location)
    }
  }

  
  constructor(props) {
    super(props);
    this.googleMapApi = props.googleMap;
    //this.divMap = React.createRef();  
    //this.initMap();
    this.mapDiv = null;
    this.googleMap = null;
    this.marker = null;
    this.infoWindow = null;
    this.onPositionChange = props.onPositionChange;
  }

  setMapDiv = (mapDiv) => {
    if(!this.hasMap()){
      console.log(mapDiv);
      this.mapDiv = mapDiv;
      this.initMap();
    }
  }

  hasMap = () => this.mapDiv !== null;
  
  location = () => ({lat: this.state.lat, lng: this.state.lng});

  initMap = () => {
    let _this = this;
    const location = {lat: this.state.lat, lng: this.state.lng};
    //this.geocoder = new google.maps.Geocoder();
    //let options : google.maps.MapOptions ={};
    this.googleMap = new this.googleMapApi.Map(this.mapDiv, {
      center: location,
      zoom: 11,
      draggable: true,
      fullscreenControl: false,
      keyboardShortcuts: false,
      mapTypeControl: false,
      panControl: false,
      scrollwheel: true,
      streetViewControl: false,
    });

    this.infoWindow = new this.googleMapApi.InfoWindow({
      maxWidth:300,
    });

    this.marker = new this.googleMapApi.Marker({ 
      map: this.googleMap, 
      draggable: true, 
      //icon: 'https://tinyurl.com/ysxjmzze',
      //anchorPoint: new this.googleMapApi.Point(64,64),
    });

    this.marker.addListener("click", (markerElement, event) => {
      this.infoWindow.open(this.googleMap, this.marker);
    })
    
    this.marker.addListener("dragend", ()=>{
      const pos = this.marker.getPosition();
      this.onPositionChange && this.onPositionChange({lat: pos.lat(), lng: pos.lng()});
    })
    
    this.googleMap.setZoom(11);
    this.googleMap.setCenter(location);
    // Set the position of the marker using the place ID and location.
    this.marker.setPosition(location);
    this.marker.setVisible(true);

    this.marker.setTitle("Corrija la ubicacion con esta marca y presione Confirmar Ubicacion");

    this.infoWindow.setContent(this.state.text);
    this.infoWindow.open(this.googleMap, this.marker);

    new this.googleMapApi.MaxZoomService().getMaxZoomAtLatLng(location, (result) => {
      
      console.log(result.zoom);

      if (result.status === "OK") {
        _this.googleMap.setZoom( Math.min( 16, result.zoom ));
      }
    });
  
  }

  render() {
    return (
      <div  style={{width:"100%", height:"100%"}} ref={this.setMapDiv}  />
    )
  }

}