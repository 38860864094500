import { makeStyles } from '@material-ui/core/styles';
import { ThemeContext } from 'styled-components';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    minHeight: "500px",
  },
  mainPage: {
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0), 
      margin: theme.spacing(0),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(1),
    }
  }
}));

export default useStyles;