import React, { useEffect } from 'react';
import {
  Grid,
  Button,
} from '@material-ui/core';
import { TextNumberGiz, TextGiz, RadioGiz, SimpleAccordion, SimpleCheck, UploadFotoGiz, SimpleGaleryBox } from '../../../helpers';
import { Dormitorio } from './dormitorio';
import { connect } from 'react-redux';
import marks from './marks';
import { getCapacidad, getCantidadCamas } from "./dormitorio"
import { usePrevious } from '../../../_core/utils';
import { SimpleActionButton } from '../../../helpers/SimpleActionButton';
import { Camera } from './Camera';
import { TakePhotoGiz } from '../../../helpers/TakePhotoGiz';

export const Paso3 = ({onBack, onNext, completed, formik, classes = {}, handleChangePanelActivo, panelActivo, tiposCamas}) => {

  const {fotos} = formik.paso3.panel1.values;
  
  const activarPanel = (panel) => {
    handleChangePanelActivo(panel);
  }

  const handlePanel1_Volver = () => {
    onBack();
  }

  const handlePanel1_Siguiente = () => {
    formik.paso3.panel1.submitForm().then(()=>{
      onNext();
    })
  }

  const handleFileConfirm = (e) => {
    console.log(e);

    formik.paso3.panel1.setFieldValue("fotos", [e, ...fotos])
  }
  

  return (
    <>
      <SimpleAccordion title="Fotos de la propiedad" subTitle="" name="panel1" activePanel={panelActivo}
        actions={[
          <SimpleActionButton label="Volver" onClick={handlePanel1_Volver} />,
          <SimpleActionButton label="Siguiente" onClick={handlePanel1_Siguiente} />,
        ]}
      >
       <Grid container>
          <Grid item xs={12}>
            <SimpleGaleryBox name="fotos" formik={formik.paso3.panel1} addPhoto={
              <UploadFotoGiz
                label="Seleccione las fotos para subir"
                name="fotos"
                formik={formik.paso3.panel1}
                onFileConfirm={handleFileConfirm}
              />
            }/>
          </Grid>
        </Grid>
      </SimpleAccordion>

    </>
  )
}

