import { useRef } from "react";

export const toDispatch = (type, payload) => ({type,payload});

export const baseAction = ( {fn, REQUEST, SUCCESS, FAILURE, getState, dispatch}) => { 
  dispatch(toDispatch(REQUEST));
  return new Promise((resolve, reject) => {
    fn()
    .then( response => {
      console.log(response.data);
      dispatch(toDispatch(SUCCESS, response.data));
      resolve(response.data)
    })
    .catch( error => {
      console.log(error);
      dispatch(toDispatch(FAILURE, error));
      reject(error)
    });
  })
}

export const decodeToken = (token) => {
  try {
    if (token.split(".").length !== 3 || typeof token !== "string") {
      return null;
    } else {
      var payload = token.split(".")[1];
      var base64 = payload.replace("-", "+").replace("_", "/");
      var decoded = JSON.parse(atob(base64));
      return decoded;
    }
  } catch (error) {
    return null;
  }
}

export const dias = {
  dom: {text: "DOMINGO", value: "dom", active: true},
  lun: {text: "LUNES", value: "lun", active: true},
  mar: {text: "MARTES", value: "mar", active: true},
  mie: {text: "MIERCOLES", value: "mie", active: true},
  jue: {text: "JUEVES", value: "jue", active: true},
  vie: {text: "VIERNES", value: "vie", active: true},
  sab: {text: "SABADO", value: "sab", active: true},
}

export const currencyFormatter = new Intl.NumberFormat('es-AR', {
  style: 'currency',
  currency: 'ARS',
});

export const price = {
  type: 'number',
  valueFormatter: ({ value }) => currencyFormatter.format(Number(value)),
  cellClassName: 'font-tabular-nums',
};

export const useFocus = () => {
  const htmlElRef = useRef(null)
  const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}
  return [ htmlElRef, setFocus ] 
}
