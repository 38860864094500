import { Card, Grid } from '@material-ui/core';
import React from 'react';
import useStyles from './style';
import Aviso from "./aviso";

const Page = ({list, isFetching}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {isFetching && <span>loading...</span>}
      {!isFetching && 
        list.map((item) => 
          <Aviso key={item.id} item={item} />
        )
      }

    </div>
  );
};

export default Page;