import React from 'react';
import Page from './page';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Avisos, PublicacionesAdd } from '../';

const _Dashboard = () => {
  const page = useParams().page;
  return (
      <Page>
        {SelectPage(page)}
      </Page>
  );
}


const SelectPage = (page) => {
  switch(page){
    case "Avisos": {
      return <Avisos />
    }
    case "Mensajes":{

    }
    case "Favoritos": {

    }
    case "Descartados" :{

    }
    case "Cuenta": {

    }
    case "Anunciante": {

    }
    case "Publicar": {
      return <PublicacionesAdd />
    }
    default: {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  };
}

const mapDispatchToProps = {

}

export const Dashboard = connect(mapStateToProps, mapDispatchToProps)(_Dashboard);

export default Dashboard;