import React, { Fragment } from 'react';
import useStyles from './style';
import logo from "../../assets/img/logo/logo-footer.png";
import { Typography, Grid } from '@material-ui/core';

export const Page = (props) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={1}/>
          <Grid item xs={10}>
            <Grid container>
              <Grid item xs={12} md={4} className={classes.footerBox}>
                <Typography variant="body1" color="inherit" className={classes.footerText}>
                  La quinta con pileta que esperabaas para tus vacaciones la encontras en Quintuus!
                </Typography>
              </Grid>
              <Grid item xs={12} md={4} className={classes.footerBox}>
                <Typography variant="h5" color="inherit" className={classes.title} noWrap>
                  Mapa del sitio
                </Typography>
                <Typography variant="body1" color="inherit" className={classes.footerText} noWrap>
                  Quintas
                </Typography>
                <Typography variant="body1" color="inherit" className={classes.footerText} noWrap>
                  Mi cuenta
                </Typography>
                <Typography variant="body1" color="inherit" className={classes.footerText} noWrap>
                  Publicar
                </Typography>
                <Typography variant="body1" color="inherit" className={classes.footerText} noWrap>
                  Noticias
                </Typography>
              </Grid>
              <Grid item xs={12} md={4} className={classes.footerBox}>
                <Typography variant="h6" color="inherit" className={classes.title} noWrap>
                  <img className={classes.logo} src={logo} alt="Logo" />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1}/>
        </Grid>

      </div>
      <div className={classes.subFooter}>
      <Grid container>
        <Grid item xs={1}/>
        <Grid item xs={10}>
          <Grid container>
            <Grid item xs={12} className>
              <Typography variant="body1" color="inherit" className={classes.footerText} noWrap>
                © 2021 Quintuus | Todos los derechos reservados
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={1}/>
      </Grid>
      </div>
    </>
  );
}