import React, { useEffect, useState } from "react"
import { TextField } from "@material-ui/core"

export const TextGiz = ({onChange = ()=>{}, name, details = false, label, caption, formik, placeholder, type, autoFocus, disabled, style = {}, inputProps = {}, multiline = false, rows = undefined}) => {

  const error = formik && formik.errors[name];
  const hasError = Boolean(error);
  const externalValue = formik.values[name];
  const [inputValue, setInputValue] = useState(externalValue);
  const [timer, setTimer] = useState(null);
  const [tocado, setTocado] = useState(false);

  useEffect(() => {
    return () => clearTimeout(timer);
  }, [])

  useEffect(() => {
    !tocado && setInputValue(externalValue);
  }, [externalValue])

  useEffect(() => {
    timer && clearTimeout(timer);
    setTimer(setTimeout(() => {
      formik.setFieldValue(name, inputValue);
      onChange({target: {name, value: inputValue}});
    }, 250));
  }, [inputValue])

  const handleInputChange = async (e) => {   
    e.preventDefault(); 
    //formik.setFieldValue(name, e.target.value)
    setInputValue(e.target.value)
    setTocado(true);
  }

  return (
    <div style={{ margin: 8 }}>
      <TextField
        rows={rows}
        multiline={multiline}
        autoFocus={autoFocus}
        fullWidth
        type={type}
        variant="outlined"
        id={name}
        name={name}
        label={caption||label}
        style={{ ...style }}
        placeholder={placeholder}
        value={inputValue}
        onChange={handleInputChange}
        error={hasError}
        helperText={error}
        disabled={disabled} 
        inputProps={inputProps}
      />
    </div>
  )
}

