import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  rootAviso: {
    padding: theme.spacing(2),
    margin: 'auto',
    backgroundColor: "white",
    borderRadius: "0px 0px 0px 0px !important",
    margin: theme.spacing(2),
    flexGrow: 1,
  },
  box:{
    padding: theme.spacing(2),
    borderRadius: "0px 0px 0px 0px !important",
    textAlign: "left",
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
    width: "150px", 
    height: "150px",
  },
  infoTitle: {
    fontWeight: "bolder"
  },
  infoBox: {
  }
}));

export default useStyles;