import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import Page from './page';
import { avisosActions } from "../../../redux/actions";

const _Avisos = ({avisos, index}) => {
  useEffect(()=>{
    index();
  },[])

  return (
      <Page {...avisos}/>
  );
}

const mapStateToProps = state => ({
  avisos: state.avisos,
});

const mapDispatchToProps = {
  index: avisosActions.index
}

export const Avisos = connect(mapStateToProps, mapDispatchToProps)(_Avisos);

export default Avisos;