import React from 'react';
import { Button, Grid, Link, Typography, CircularProgress  } from '@material-ui/core';
import { ContentWrapper, TextGiz, DatePicker, SwitchGiz } from '../../helpers';

import useStyles from './style';
import { Link as RouterLink } from 'react-router-dom';

const Page = ({title, subTitle, formik, handleChange}) => {
  const classes = useStyles();

  const handleChangeDate = (name, value) => {
    handleChange({target:{name: name, value: value}})
  }

  return (
     <div className={classes.root}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6} xl={4}>

            <ContentWrapper title={title} subTitle={subTitle}>
              <form onSubmit={formik.handleSubmit} >

                <Grid container spacing={1}>

                  <Grid item xs={12}>
                    <TextGiz
                      name="ubicacion"
                      label="Ubicación"
                      formik={formik}
                      onChange={handleChange}
                      placeholder="Ingrese una ubicación o el nombre de una quinta"
                    />
                  </Grid>
                  <Grid item xs={12} md={6} xl={6}>
                    <DatePicker
                      name= "llegada"
                      formik={formik}
                      placeholder="Llegada"
                      onChange={(value)=>{handleChangeDate("llegada", value)}}
                      minDate={new Date()}
                      invalidDateMessage="La fecha es inválida"
                      minDateMessage="La fecha es inválida"
                      maxDateMessage="La fecha es inválida"                    
                    />
                  </Grid>
                  <Grid item xs={12} md={6} xl={6}>
                    <DatePicker
                      name= "salida"
                      formik={formik}
                      placeholder="Salida"
                      onChange={(value)=>{handleChangeDate("salida", value)}}
                      minDate={formik.values.llegada && formik.values.llegada}
                      invalidDateMessage="La fecha es inválida"
                      minDateMessage="La fecha es inválida"
                      maxDateMessage="La fecha es inválida"                    
                    />
                  </Grid>

                  <Grid item xs={12} md={6} xl={6}>
                    <TextGiz
                      name="cantidadPersonas"
                      label="Cantidad de personas"
                      formik={formik}
                      onChange={handleChange}
                      placeholder="Cantidad de personas"                  
                    />
                  </Grid>
                  <Grid item xs={12} md={6} xl={6}>
                    <div className={classes.aceptaMascotas}>
                      <SwitchGiz
                        title="Se aceptan mascotas?"
                        name="aceptaMascotas"
                        label="Se aceptan mascotas?"
                        formik={formik}
                        onChange={handleChange}
                        placeholder="Necesita incluir mascotas?"                  
                      />
                    </div>
                  </Grid>


                  <Grid item xs={12}>
                    <Button 
                      type="submit" 
                      variant="contained" 
                      color="secondary" 
                      disabled={formik.isSubmitting} 
                      className={classes.buttonQuintuus}
                      startIcon={formik.isSubmitting &&  <CircularProgress />}
                    >
                      Buscar quintas
                    </Button>
                      
                    </Grid>


                </Grid>

      
              </form>
            </ContentWrapper>

          </Grid>
          <Grid item xs={12} md={6} xl={8}>
          </Grid>
        </Grid>

     </div>


   

  );
};

export default Page;