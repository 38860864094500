import React from "react"
import { DatePicker as Dtpicker } from "@material-ui/pickers";

export const DatePicker = ({name, label = "Fecha", formik, placeholder, onChange, minDate, invalidDateMessage, minDateMessage, maxDateMessage}) => {

  return (
    <div style={{ padding: 8 }}>
    <Dtpicker
      fullWidth
      InputAdornmentProps={{ position: "start" }}
      value={formik.values[name]}
      placeholder={placeholder}
      onChange={(value)=>{ 
        console.log({formikValue:formik.values[name],  value});
        formik.setFieldValue(name, value)
        //onChange( {target: { name, value}} )
      }}
      minDate={minDate}
      format="DD/MM/YYYY"
      autoOk={true}
      invalidDateMessage={invalidDateMessage}
      minDateMessage={minDateMessage}
      maxDateMessage={maxDateMessage}
      inputVariant = "outlined" 
      
      invalidLabel={formik.touched[name] && Boolean(formik.errors[name])}
      label={label}
    />
    </div>
  )
}

