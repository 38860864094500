import React from 'react';
import { Grid, CardContent, Card } from '@material-ui/core';

export const WrapperComponent = ({children}) => {
  return (
    <Grid item xs={12} md={6} lg={4}>
      <Card square>
        <CardContent>
          {children}        
        </CardContent>
      </Card>
    </Grid>   
  )
} 
