import React from 'react';


import { Paso1 } from "../../app/components/Forms/Publicacion/paso1";
import { Paso2 } from "../../app/components/Forms/Publicacion/paso2";
import { Paso3 } from "../../app/components/Forms/Publicacion/paso3";
import { Paso4 } from "../../app/components/Forms/Publicacion/paso4";
import { Paso5 } from "../../app/components/Forms/Publicacion/paso5";
import { Paso6 } from "../../app/components/Forms/Publicacion/paso6";
import { StarIcon, HouseUserIcon } from "../../app/helpers/icons/";
import { publicacionesConstants as constants } from "../constants";

const tiposCamas = [
  {descripcion: "Cama simple (1 plaza)", nombre: "simple", cantidadPersonas: 1, cantidad: 0, visible: true},
  {descripcion: "Cama doble (2 plazas)", nombre: "doble", cantidadPersonas: 2, cantidad: 0, visible: true},
  {descripcion: "Queen (2 plazas)", nombre: "queen", cantidadPersonas: 2, cantidad: 0, visible: true},
  {descripcion: "King (2 plazas)", nombre: "king", cantidadPersonas: 2, cantidad: 0, visible: false},
  {descripcion: "Cama cucheta (2 plazas)", nombre: "cucheta1", cantidadPersonas: 2, cantidad: 0, visible: false},
  {descripcion: "Futon (1 plaza)", nombre: "futon1", cantidadPersonas: 1, cantidad: 0, visible: false},
  {descripcion: "Futon (2 plazas)", nombre: "futon2", cantidadPersonas: 2, cantidad: 0, visible: false},
  {descripcion: "Sofá (1 plaza)", nombre: "sofa", cantidadPersonas: 1, cantidad: 0, visible: false},
];

const defaultState = {
  isFetching: false, 
  list: [],
  tiposCamas: tiposCamas,
  item: {
    id: 0,
    titulo: "",
    descripcion: "",
    mapUrl: "",
    ubicacion: "",
    ubicacionConfirmada: false,
    direccion: "",
    barrio: "",
    localidad: "",
    partido: "",
    provincia: "",
    pais: "",
    lat: 0,
    lng: 0,
    mostrarLocalización: "",
    modalidad: "",
    mostrarComodidadesCaracteristicas: "",
    cantidadDormitorios: 0,
    dormitorios: [],
    cantidadEspaciosCompartidos: 1,
    espaciosCompartidos: [{camas: tiposCamas}],
    cantidadCamasHuespedes: 0,
    cantidadPersonas: 0,
    cantidadBanos: 0,
    cantidadToilettes: 0,
    aceptaJovenes: false,
    aceptaMascotas: false,
    superficieConstruida: "",
    superficieTotal: "",
    fotos: [],
    calendario: {
      desde: "2020-06-01",
      habilitadoHasta: "2022-06-01",
      precioBase: 1500,
      diasFinDeSemana: 0,
      precioFinDeSemana:  2500,
      fechas: [
        {
          fecha: "2021-05-01",
          libre: true,
          precio: 1500,
        },
        {
          fecha: "2021-05-02",
          libre: true,
          precio: 1500,
        },
        {
          fecha: "2021-05-03",
          libre: true,
          precio: 1500,
        },
        {
          fecha: "2021-05-04",
          libre: true,
          precio: 1500,
        },
        {
          fecha: "2021-05-05",
          libre: true,
          precio: 1500,
        },
        {
          fecha: "2021-05-06",
          libre: true,
          precio: 1500,
        },
        {
          fecha: "2021-05-07",
          libre: true,
          precio: 1500,
        },
        {
          fecha: "2021-05-08",
          libre: true,
          precio: 1500,
        },
        {
          fecha: "2021-05-09",
          libre: true,
          precio: 1500,
        },
        {
          fecha: "2021-05-10",
          libre: false,
          precio: 1500,
        },
        {
          fecha: "2021-05-11",
          libre: false,
          precio: 1500,
        },
      ],
    },
    seccionEntretenimiento: {
      pool: false,
      tenisDeMesa: false,
      juegosDeMesa: false,
      metegol: false,
      bicicletas: false,
      consolaDeJuegos: false,
    },
    seccionEquipamiento: {
      cocinaTotalmenteEquipada: false,
      vajillasCubiertos: false,
      heladeraConFrezzer: false,
      frezzer: false,
      calefacccionCentral: false,
      equipoDeMusica: false,
      frigobar: false,
      hornoMicroondas: false,
      hornoElectrico: false,
      tostadora: false,
      estufa: false,
      lavarropas: false,
      secarropas: false,
      cafetera: false,
      pavaElectrica: false,
      lavaplatos: false,
      secadorDePelos: false,
      ventiladorDePie: false,
      ventiladorDeTecho: false,
      plancha_TablaDePlanchar: false,

    },
    seccionFavoritos: {
      wifi: false,
      aireAcondicionado: false,
      aireAcondicionado_Frio: false,
      aireAcondicionado_Frio_Calor: false,
      televisor: false,
      canalesCable: false,
      pileta: false,
      pileta_ExteriorClimatizada: false,
      pileta_ExteriorClimatizada_ConCerco: false,
      pileta_ExteriorNoClimatizada: false,
      pileta_ExteriorNoClimatizada_ConCerco: false,
      piletaCubierta: false,
      piletaInfantil: false,
      piletaInfantil_Climatizada: false,
      piletaInfantil_ConCerco: false,
      asador: false,
      parrilla: false,
      garageCubierto: false,
      garageCubierto_Cantidad: 0,
      garageDescubierto: false,
      garageDescubierto_Cantidad: 0,
    },
    seccionInstalaciones: {
      chimenea: false,
      hogarLena: false,
      salamandra: false,
      jacuzzi: false,
      sauna: false,
      gimnasio: false,
      plazaDeJuegos: false,
      canchaDeTenis: false,
      canchaDeFutbol: false,
      canchaDeVoley: false,
    },
  },
  stepper: {
    pasos : {
      datos: {
        index: 0,
        content: Paso1,
        route: "/Dashboard/Publicar/TituloUbicacion",
        icon: (data) => <HouseUserIcon height={20} width={20} primaryColor="#72c1de" secondaryColor="red"/>,
        isCompleted: (data) => true,
        title: "Título y ubicación",
      },
      detalles: {
        index: 1,
        content: Paso2,
        route: "/Dashboard/Publicar/Detalles",
        icon: (data) => <StarIcon height={20} width={20} primaryColor="blue" secondaryColor="red"/>,
        isCompleted: (data) => true,
        title: "Modalidad y detalles",
      },
      fotos: {
        index: 2,
        content: Paso3,
        route: "/Dashboard/Publicar/Fotos",
        icon: (data) => <StarIcon height={20} width={20} primaryColor="blue" secondaryColor="red"/>,
        isCompleted: (data) => true,
        title: "Fotos",
      },
      calendario: {
        index: 3,
        content: Paso4,
        route: "/Dashboard/Publicar/Calendario",
        icon: (data) => <StarIcon height={20} width={20} primaryColor="blue" secondaryColor="red"/>,
        isCompleted: (data) => true,
        title: "Calendario y precios",
      }
    }
  }
};

export const reducer = (state = defaultState, {type, payload}) => {
  switch(type){
    case constants.UPDATE_ITEM.SUCCESS:
      return {
        ...state,
        item: payload,
      }
    case constants.ADD_DORMITORIO: 
      return {
        ...state,
        item: {
          ...state.item,
          dormitorios: {
            ...state.item.dormitorios,
            payload
          },
          cantidadDormitorios: state.item.cantidadDormitorios + 1,
        }
      }
    case constants.ADD_DORMITORIO: 
      return {
        ...state,
        item: {
          ...state.item,
          dormitorios: {
            ...state.item.dormitorios,
            payload
          },
          cantidadDormitorios: state.item.cantidadDormitorios + 1,
        }
      }
    case "jdkls": 
      return {
        ...state,
        stepper: {
          ...state.stepper,
          pasos: {
            ...state.stepper.pasos,
            [payload.step]: {
              ...state.stepper.pasos[payload.step],
              title: payload
            }  
          }
        }
      }
    case constants.INDEX.REQUEST:
      return {
        ...state,
        isFetching: true, 
      };
    case constants.INDEX.SUCCESS:
      return {
        ...state,
        list: payload,
        isFetching: false, 
      };      
    case constants.INDEX.FAILURE:
      return {
        ...state,
        list: [],
        isFetching: false, 
      };  
    case constants.GET_ITEM.REQUEST:
      return {
        ...state,
        isFetching: true, 
      };
    case constants.GET_ITEM.SUCCESS:
      return {
        ...state,
        isFetching: false, 
      };      
    case constants.GET_ITEM.FAILURE:
      return {
        ...state,
        isFetching: false, 
      };  
    case constants.ADD_ITEM.REQUEST:
      return {
        ...state,
        isFetching: true, 
      };
    case constants.ADD_ITEM.SUCCESS:
      return {
        ...state,
        list: [payload, ...state.list],
        isFetching: false, 
      };      
    case constants.ADD_ITEM.FAILURE:
      return {
        ...state,
        isFetching: false, 
      };  
    case constants.ADD_FORM.REQUEST:
      return {
        ...state,
        isFetching: true, 
      };
    case constants.ADD_FORM.SUCCESS:
      return {
        ...state,
        isFetching: false, 
      };      
    case constants.ADD_FORM.FAILURE:
      return {
        ...state,
        isFetching: false, 
      };  
    case constants.EDIT_FORM.REQUEST:
      return {
        ...state,
        isFetching: true, 
      };
    case constants.EDIT_FORM.SUCCESS:
      return {
        ...state,
        isFetching: false, 
      };      
    case constants.EDIT_FORM.FAILURE:
      return {
        ...state,
        isFetching: false, 
      };  
      case constants.REMOVE_ITEM.REQUEST:
        return {
          ...state,
          isFetching: true, 
        };
      case constants.REMOVE_ITEM.SUCCESS:
        return {
          ...state,
          list: state.list.filter(x => x.id !== payload),
          isFetching: false, 
        };      
      case constants.REMOVE_ITEM.FAILURE:
        return {
          ...state,
          isFetching: false, 
        };  
            
    default:
      return state;
  }
}