import { Typography, Grid, Slider,  } from "@material-ui/core";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { TextNumberGiz } from "../../../helpers";
import marks from "./marks"




export const Cama = ({cama, onChange}) => {

  const {marks5} = marks;
  //const [data, setData] = useState(cama)
  
  const formik = useFormik({
    initialValues: cama,
  })

  const data = formik.values;

  useEffect(()=>{
    onChange(data)
  },[data])

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography id="discrete-slider" gutterBottom>
        {cama.descripcion} {cama.cantidad!==0 && <> x {cama.cantidad}</>} 
        </Typography>
        {/*<Slider
          name="cantidad"
          value={cama.cantidad}
          onChange={(e)=>{handleChange(e)}}
          aria-labelledby="discrete-slider"
          valueLabelDisplay="auto"
          step={1}
          marks={marks5}
          min={0}
          max={5}
        />*/}
        <TextNumberGiz 
          name="cantidad"
          formik={formik}
          aria-labelledby="discrete-slider"
          valueLabelDisplay="auto"
          min={0}
          max={5}     
          error=""
          helperText=""
          slim={true}   
        />
      </Grid>
    </Grid>
    
  )
}