import React, { useEffect } from 'react';
import Page from './page';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { publicacionesActions } from '../../../../redux/actions';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { useSnackContext } from '../../../contexts/Snack';
import moment from "moment";

const validationSchema = {
  paso1: {
    panel1: yup.object({
      titulo: yup.string().required("Dato requerido").min(5, "Mínimo 5 caractéres").max(50, "Máximo 50 caractéres"),
    }),
    panel2: yup.object({
      mapUrl: yup.string(),
      ubicacion: yup.string().max(1000, "Máximo 1000 caractéres").required("Dato requerido"),
      ubicacionConfirmada: yup.string(),
      lat: yup.number().required("Dato requerido"),
      lng: yup.number().required("Dato requerido"),
      mostrarLocalizacion: yup.string().required("Dato requerido"),
    }),
    panel3: yup.object({
      direccion: yup.string().required("Dato requerido").max(100),
      barrio: yup.string().max(100, "Máximo 50 caractéres"),
      localidad: yup.string().required("Dato requerido").max(100, "Máximo 50 caractéres"),
      partido: yup.string().required("Dato requerido").max(100, "Máximo 50 caractéres"),
      provincia: yup.string().required("Dato requerido").max(100, "Máximo 50 caractéres"),
      pais: yup.string().required("Dato requerido").max(100, "Máximo 100 caractéres"),
    }),
  },
  paso2: {
    panel1: yup.object({
      modalidad: yup.string().required(),
    }),
    panel2: yup.object({
      mostrarComodidadesCaracteristicas: yup.bool(),
      cantidadDormitorios: yup.number(),
      dormitorios: yup.array(),
      cantidadEspaciosCompartidos: yup.number(),
      espaciosCompartidos: yup.array(),
      cantidadCamasHuespedes: yup.number(),
      cantidadPersonas: yup.number().min(1, "El valor mínimo es 1.").required("Dato requerido"),
      cantidadBanos: yup.number().required("Dato requerido"),
      cantidadToilettes: yup.number().required("Dato requerido"),
      aceptaJovenes: yup.number().required("Dato requerido"),
      aceptaMascotas: yup.number().required("Dato requerido"),
      superficieConstruida: yup.number().required("Dato requerido"),
      superficieTotal: yup.number(),
    }),
    panel3_1: yup.object({
      wifi: yup.bool(),
      aireAcondicionado: yup.bool(),
      aireAcondicionado_Frio: yup.bool(),
      aireAcondicionado_Frio_Calor: yup.bool(),
      televisor: yup.bool(),
      canalesCable: yup.bool(),
      pileta: yup.bool(),
      pileta_ExteriorClimatizada: yup.bool(),
      pileta_ExteriorClimatizada_ConCerco: yup.bool(),
      pileta_ExteriorNoClimatizada: yup.bool(),
      pileta_ExteriorNoClimatizada_ConCerco: yup.bool(),
      piletaCubierta: yup.bool(),
      piletaInfantil: yup.bool(),
      piletaInfantil_Climatizada: yup.bool(),
      piletaInfantil_ConCerco: yup.bool(),
      asador: yup.bool(),
      parrilla: yup.bool(),
      garageCubierto: yup.bool(),
      garageCubierto_Cantidad: yup.number(),
      garageDescubierto: yup.bool(),
      garageDescubierto_Cantidad: yup.number(),
    }),
    panel3_2: yup.object({
      cocinaTotalmenteEquipada: yup.bool(),
      vajillasCubiertos: yup.bool(),
      heladeraConFrezzer: yup.bool(),
      frezzer: yup.bool(),
      calefacccionCentral: yup.bool(),
      equipoDeMusica: yup.bool(),
      frigobar: yup.bool(),
      hornoMicroondas: yup.bool(),
      hornoElectrico: yup.bool(),
      tostadora: yup.bool(),
      estufa: yup.bool(),
      lavarropas: yup.bool(),
      secarropas: yup.bool(),
      cafetera: yup.bool(),
      pavaElectrica: yup.bool(),
      lavaplatos: yup.bool(),
      secadorDePelos: yup.bool(),
      ventiladorDePie: yup.bool(),
      ventiladorDeTecho: yup.bool(),
      plancha_TablaDePlanchar: yup.bool(),
    }),
    panel3_3: yup.object({
      chimenea: yup.bool(),
      hogarLena: yup.bool(),
      salamandra: yup.bool(),
      jacuzzi: yup.bool(),
      sauna: yup.bool(),
      gimnasio: yup.bool(),
      plazaDeJuegos: yup.bool(),
      canchaDeTenis: yup.bool(),
      canchaDeFutbol: yup.bool(),
      canchaDeVoley: yup.bool(),
    }),
    panel3_4: yup.object({
      pool: yup.bool(),
      tenisDeMesa: yup.bool(),
      juegosDeMesa: yup.bool(),
      metegol: yup.bool(),
      bicicletas: yup.bool(),
      consolaDeJuegos: yup.bool(),
    }),
    panel7: yup.object({
      descripcion: yup.string().optional(),
    })
  },

  paso3: {
    panel1: yup.object({
      fotos: yup.array(),
    })
  },
  paso4: {
    panel1: yup.object({
      calendario: yup.object(),
    })
  },

};



export const _Index = ({addForm, editForm, addItem, editItem, title = "", subTitle = "", item}) => {
  const fmk = {
    paso1: {
      panel1: useFormik({
        initialValues: {
          titulo: item.titulo,
        },
        validationSchema: validationSchema.paso1.panel1,
        validateOnMount: true,
        onSubmit: values => {        
          console.log({action: "guardar datos", values})   
        },
      }),
      panel2: useFormik({
        initialValues: {
          mapEdit: false,
          mapUrl: item.mapUrl,
          ubicacion: item.ubicacion,
          ubicacionConfirmada: item.ubicacionConfirmada,
          lat: item.lat,
          lng: item.lng,
          mostrarLocalizacion: item.mostrarLocalizacion,
        },
        validationSchema: validationSchema.paso1.panel2,
        validateOnMount: true,
        onSubmit: values => {        
          console.log({action: "guardar datos", values})   
        },
      }),
      panel3: useFormik({
        initialValues: {
          direccion: item.direccion,
          barrio: item.barrio,
          localidad: item.localidad,
          partido: item.partido,
          provincia: item.provincia,
          pais: item.pais,
        },
        validationSchema: validationSchema.paso1.panel3,
        validateOnMount: true,
        onSubmit: values => {        
          console.log({action: "guardar datos", values})   
        },
      }),

    },
    paso2: {
      panel1: useFormik({
        initialValues: {
          modalidad: item.modalidad,
        },
        validationSchema: validationSchema.paso2.panel1,
        validateOnMount: true,
        onSubmit: values => {        
          console.log({action: "guardar datos", values})   
        },
      }),
      panel2: useFormik({
        initialValues: {
          mostrarComodidadesCaracteristicas: item.mostrarComodidadesCaracteristicas,
          cantidadDormitorios: item.cantidadDormitorios,
          dormitorios: item.dormitorios,
          cantidadEspaciosCompartidos: item.cantidadEspaciosCompartidos,
          espaciosCompartidos: item.espaciosCompartidos,
          cantidadCamasHuespedes: item.cantidadCamasHuespedes,
          cantidadPersonas: item.cantidadPersonas,
          cantidadBanos: item.cantidadBanos,
          cantidadToilettes: item.cantidadToilettes,
          aceptaJovenes: item.aceptaJovenes,
          aceptaMascotas: item.aceptaMascotas,
          superficieConstruida: item.superficieConstruida,
          superficieTotal: item.superficieTotal,
        },
        validationSchema: validationSchema.paso2.panel2,
        validateOnMount: true,
        onSubmit: values => {        
          console.log({action: "guardar datos", values})   
        },
      }),
      panel3_1: useFormik({
        initialValues: {
          wifi: item.seccionFavoritos.wifi,
          aireAcondicionado: item.seccionFavoritos.aireAcondicionado,
          aireAcondicionado_Frio: item.seccionFavoritos.aireAcondicionado_Frio,
          aireAcondicionado_Frio_Calor: item.seccionFavoritos.aireAcondicionado_Frio_Calor,
          televisor: item.seccionFavoritos.televisor,
          canalesCable: item.seccionFavoritos.canalesCable,
          pileta: item.seccionFavoritos.pileta,
          pileta_ExteriorClimatizada: item.seccionFavoritos.pileta_ExteriorClimatizada,
          pileta_ExteriorClimatizada_ConCerco: item.seccionFavoritos.pileta_ExteriorClimatizada_ConCerco,
          pileta_ExteriorNoClimatizada: item.seccionFavoritos.pileta_ExteriorNoClimatizada,
          pileta_ExteriorNoClimatizada_ConCerco: item.seccionFavoritos.pileta_ExteriorNoClimatizada_ConCerco,
          piletaCubierta: item.seccionFavoritos.piletaCubierta,
          piletaInfantil: item.seccionFavoritos.piletaInfantil,
          piletaInfantil_Climatizada: item.seccionFavoritos.piletaInfantil_Climatizada,
          piletaInfantil_ConCerco: item.seccionFavoritos.piletaInfantil_ConCerco,
          asador: item.seccionFavoritos.asador,
          parrilla: item.seccionFavoritos.parrilla,
          garageCubierto: item.seccionFavoritos.garageCubierto,
          garageCubierto_Cantidad: item.seccionFavoritos.garageCubierto_Cantidad,
          garageDescubierto: item.seccionFavoritos.garageDescubierto,
          garageDescubierto_Cantidad: item.seccionFavoritos.garageDescubierto_Cantidad,
        },
        validationSchema: validationSchema.paso2.panel3_1,
        validateOnMount: true,
        onSubmit: values => {        
          console.log({action: "guardar datos", values})   
        },
      }),
      panel3_2: useFormik({
        initialValues: {
          cocinaTotalmenteEquipada: item.seccionEquipamiento.cocinaTotalmenteEquipada,
          vajillasCubiertos: item.seccionEquipamiento.vajillasCubiertos,
          heladeraConFrezzer: item.seccionEquipamiento.heladeraConFrezzer,
          frezzer: item.seccionEquipamiento.frezzer,
          calefacccionCentral: item.seccionEquipamiento.calefacccionCentral,
          equipoDeMusica: item.seccionEquipamiento.equipoDeMusica,
          frigobar: item.seccionEquipamiento.frigobar,
          hornoMicroondas: item.seccionEquipamiento.hornoMicroondas,
          hornoElectrico: item.seccionEquipamiento.hornoElectrico,
          tostadora: item.seccionEquipamiento.tostadora,
          estufa: item.seccionEquipamiento.estufa,
          lavarropas: item.seccionEquipamiento.lavarropas,
          secarropas: item.seccionEquipamiento.secarropas,
          cafetera: item.seccionEquipamiento.cafetera,
          pavaElectrica: item.seccionEquipamiento.pavaElectrica,
          lavaplatos: item.seccionEquipamiento.lavaplatos,
          secadorDePelos: item.seccionEquipamiento.secadorDePelos,
          ventiladorDePie: item.seccionEquipamiento.ventiladorDePie,
          ventiladorDeTecho: item.seccionEquipamiento.ventiladorDeTecho,
          plancha_TablaDePlanchar: item.seccionEquipamiento.plancha_TablaDePlanchar,
        },
        validationSchema: validationSchema.paso2.panel3_2,
        validateOnMount: true,
        onSubmit: values => {        
          console.log({action: "guardar datos", values})   
        },
      }),
      panel3_3: useFormik({
        initialValues: {
          chimenea: item.seccionInstalaciones.chimenea,
          hogarLena: item.seccionInstalaciones.hogarLena,
          salamandra: item.seccionInstalaciones.salamandra,
          jacuzzi: item.seccionInstalaciones.jacuzzi,
          sauna: item.seccionInstalaciones.sauna,
          gimnasio: item.seccionInstalaciones.gimnasio,
          plazaDeJuegos: item.seccionInstalaciones.plazaDeJuegos,
          canchaDeTenis: item.seccionInstalaciones.canchaDeTenis,
          canchaDeFutbol: item.seccionInstalaciones.canchaDeFutbol,
          canchaDeVoley: item.seccionInstalaciones.canchaDeVoley,
        },
        validationSchema: validationSchema.paso2.panel3_3,
        validateOnMount: true,
        onSubmit: values => {        
          console.log({action: "guardar datos", values})   
        },
      }),
      panel3_4: useFormik({
        initialValues: {
          pool: item.seccionEntretenimiento.pool,
          tenisDeMesa: item.seccionEntretenimiento.tenisDeMesa,
          juegosDeMesa: item.seccionEntretenimiento.juegosDeMesa,
          metegol: item.seccionEntretenimiento.metegol,
          bicicletas: item.seccionEntretenimiento.bicicletas,
          consolaDeJuegos: item.seccionEntretenimiento.consolaDeJuegos,
        },
        validationSchema: validationSchema.paso2.panel3_4,
        validateOnMount: true,
        onSubmit: values => {        
          console.log({action: "guardar datos", values})   
        },
      }),
      panel7: useFormik({
        initialValues: {
          descripcion: item.descripcion,
        },
        validationSchema: validationSchema.paso2.panel7,
        validateOnMount: true,
        onSubmit: values => {        
          console.log({action: "guardar datos", values})   
        },
      }),
    },
    paso3: {
      panel1: useFormik({
        initialValues: {
          fotos: item.fotos,
        },
        validationSchema: validationSchema.paso3.panel1,
        validateOnMount: true,
        onSubmit: values => { 
          console.log({action: "guardar datos", values})   
        },
      }),
    },
    paso4: {
      panel1: useFormik({
        initialValues: {
          ...item.calendario,
          habilitadoHasta: moment(item.calendario.habilitadoHasta),
        },
        validationSchema: validationSchema.paso4.panel1,
        validateOnMount: true,
        onSubmit: values => { 
          console.log({action: "guardar datos", values})   
        },
      }),
    },

  };

  const formik = {
    ...fmk,
    setSubmitting: (isSubmitting) => {
      fmk.paso1.panel1.setSubmitting(isSubmitting);
    },
    values: () => ({
      ...fmk.paso1.panel1.values,
    })
  }

  

  let { id } = useParams()

  const { openAxiosError, openSuccess } = useSnackContext();

  useEffect(() => {
    getData();
  }, [])

  const getData = () => {
    const fn = id ? () => editForm(id) : () => addForm();
    formik.setSubmitting(true);
    fn()
    .then(response => {
      formik.setSubmitting(false);
      response.item && formik.setValues({
          ...response.item
        });
    })
    .catch(error => {
      formik.setSubmitting(false);
      openAxiosError(error);
    })
  }

  const handleChange = (event) => {
    //handleControlChange && handleControlChange[event.target.name] && handleControlChange[event.target.name](event.target.value); 
    formik.handleChange(event);
  }

  return (
      <Page {...{formik, title, subTitle, handleChange}}/>
  );

}

const mapStateToProps = state => ({
  item: state.publicaciones.item,
});

const mapDispatchToProps = {
  addForm: publicacionesActions.addForm,
  editForm: publicacionesActions.editForm,
  addItem: publicacionesActions.addItem,
  editItem: publicacionesActions.editItem,
}

export const Index = connect(mapStateToProps, mapDispatchToProps)(_Index);
