import React, { useEffect, useState } from 'react';
import { List, ListItem, ImageList, ListItemText, Grid, Box} from '@material-ui/core';
import {sortableContainer, sortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';

const itemStyle = {marginTop: "8px", backgroundColor: "#2aaede05" }

const SortableItem = sortableElement(({item}) => 
  <ListItem key={item.name}  style={{...itemStyle, border: "1px #2aaede solid"}}>
    <Grid container>
      <Grid item xs={1}>
        {item.index + 1}
      </Grid>
      <Grid item>
        <img
          srcSet={`${item.src}`}
          alt={item.name}
          loading="lazy"
          style={{height: "80px"}}
        />
      </Grid>
      <Grid item>
        {item.nombre} 
        <br/>
        {item.descripcion}
      </Grid>
    </Grid>
  </ListItem>
);

export const SimpleGaleryBox = ({name, formik, addPhoto}) => {


const SortableContainer = sortableContainer(({children}) => {
  return <List> <ListItem style={{...itemStyle, border: "1px #2aaede dashed"}}>{addPhoto}</ListItem>{children}</List>;
});

  const items = formik.values[name];

  //const [items, setItems] = useState(formikValue);

  //useEffect(()=>{
  //  setItems(formikValue);
  //}, [formikValue])

  const onSortEnd = ({oldIndex, newIndex}) => {
    //setItems(arrayMove(items, oldIndex, newIndex));
    formik.setFieldValue(name, arrayMove(items, oldIndex, newIndex));
  };

  return (
    <Box style={{ width: "100%"}}>
      <SortableContainer onSortEnd={onSortEnd}> 
        {items.map((item, index) => (
          <SortableItem key={`item-${index}`} index={index} item={{...item, index}} />
        ))}
      </SortableContainer>
    </Box>
  );
}