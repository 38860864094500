import React from 'react';
import { connect } from 'react-redux';
import Page from './page';
import {authActions, appBarActions} from "../../../redux/actions";

import { useHistory } from 'react-router-dom';

const _AppBar = (props) => {

  let history = useHistory();
  //const [anchorMiCuenta, setAnchorMiCuenta] = React.useState(null);
  //const [openLodingDialog, setOpenLoginDialog] = React.useState(false);

  const handleMiCuentaOpen = (event) => {
    props.openMiCuenta(event.currentTarget);
    console.log(props.auth)
  };

  const handleMiCuentaClose = () => {
    props.closeMiCuenta();
  };

  const handleLoginDialogOpen = (event) => {
    event.preventDefault();
    props.closeMiCuenta();
    props.openLoginDialog();
  }

  const handleLoginDialogClose = () => {
    props.closeLoginDialog();
  }
  
  const handleLogoutClick = (event) => {
    event.preventDefault();
    props.closeMiCuenta();
    props.logout();
    history.replace("/");
  }


  return (
      <Page {...props} {...{handleMiCuentaClose, handleMiCuentaOpen, handleLoginDialogOpen, handleLoginDialogClose, handleLogoutClick}}/>
  );
};

const mapStateToProps = state => ({
    auth: state.auth,
    ...state.appBar
});

const mapDispatchToProps = {
  logout: authActions.logout,
  openLoginDialog: appBarActions.openLoginDialog,
  closeLoginDialog: appBarActions.closeLoginDialog,
  openMiCuenta: appBarActions.openMiCuenta,
  closeMiCuenta: appBarActions.closeMiCuenta,
}

export const AppBar = connect(mapStateToProps, mapDispatchToProps)(_AppBar);

export default AppBar;