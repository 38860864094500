import React from 'react';
import { Button } from '@material-ui/core';

export const SimpleActionButton = ({label, onClick, color = "primary", size = "normal", variant="contained", disabled=false}) => {

  return (
    <Button variant={variant} size={size} color={color} onClick={onClick} disabled={disabled}>
      {label}
    </Button>
  )
}
