import React from 'react';
import { WrapperComponent } from './WrapperComponent';
import { RadioGiz } from './RadioGiz';

export const SimpleRadio = ({label, name, formik, children, wrapper = true, options}) => {

  const _wrapper = wrapper ? WrapperComponent : "div";

  return (
    <_wrapper>
      <RadioGiz {...{label, name, formik}} options={options} />
    </_wrapper>
  )
}
