import { useApiAuth as api } from '../../app/services/ApiService';
import { signInWithFacebook, signInWithGoogle } from '../../firebase/client';
import { authConstants as constants } from '../constants';
import { decodeToken, toDispatch } from '../utils';


const saveToken = (authData) => {
  removeToken();
  localStorage.setItem("authData", JSON.stringify(authData));
}

export const loadToken = () => {
  return JSON.parse(localStorage.getItem("authData"));
}

const removeToken = () => {
  localStorage.removeItem("authData");
}

const load = () => (dispatch, getState) => {
  const authData = loadToken();
  if(authData && authData.token){
    api().reloadToken(authData.token)
    .then( response => {
      saveToken(response.data);
      dispatch(toDispatch(constants.RELOAD_SUCCESS, {...decodeToken(response.data.token), token: response.data.token}));
    })
    .catch( error => {
      dispatch(toDispatch(constants.RELOAD_FAILURE, error));
    })
  } else {
    dispatch(toDispatch(constants.RELOAD_EMPTY_TOKEN));
  }
}


const login = ({usuario, password}) => (dispatch, getState) => {
  dispatch(toDispatch(constants.LOGIN_REQUEST));
  return new Promise( (resolve, reject) => {
    api().login(usuario, password)
      .then( response => {
        resolve(response);
        saveToken(response.data);
        dispatch(toDispatch(constants.LOGIN_SUCCESS, {...decodeToken(response.data.token), token: response.data.token}));
      })
      .catch( error => {
        reject(error);
        dispatch(toDispatch(constants.LOGIN_FAILURE, error));
      }) 
  } );
}

const loginWithGoogle =  () => (dispatch, getState) => {
  return loginWithExternalAuthFn(signInWithGoogle, dispatch,
    (result)=>({
      token: result.credential.idToken,
      email: result.user.email,
      nombre: result.user.displayName,
      fotoURL: result.user.photoURL,
      origen: "google.com",
      userName: result.user.email
    })
  );
}

const loginWithFacebook =  () => (dispatch, getState) => {
  return loginWithExternalAuthFn(signInWithFacebook, dispatch,    
    (result)=>({
      token: result.credential.accessToken,
      email: result.user.email,
      nombre: result.user.displayName,
      fotoURL: result.user.photoURL,
      origen: "facebook.com",
      userName: result.user.email
    })
  );
}

const loginWithExternalAuthFn = (signInFn, dispatch, requestFn) =>{

  dispatch(toDispatch(constants.LOGIN_REQUEST));
  return new Promise((resolve, reject) => {
    signInFn()
      .then((result) => {
        api().validateToken(requestFn(result))
        .then( response => {
          resolve(response);
          saveToken(response.data);
          dispatch(toDispatch(constants.LOGIN_SUCCESS, {...decodeToken(response.data.token), token: response.data.token}));
        })
        .catch( error => {
          reject(error);
          dispatch(toDispatch(constants.LOGIN_FAILURE, error));
        })
      }).catch((error) => {
        reject(error); 
        dispatch(toDispatch(constants.LOGIN_FAILURE, error));
      });
  })


}

const autoReLogin = () => (dispatch, getState) => {
  const token = getState().auth.token;
  if(token){
    api().reloadToken(token)
    .then( response => {
      saveToken(response.data);
      dispatch(toDispatch(constants.RELOAD_SUCCESS, response.data));
    })
    .catch( error => {
      dispatch(toDispatch(constants.RELOAD_FAILURE, error));
    })
  } else {
    dispatch(toDispatch(constants.RELOAD_EMPTY_TOKEN));
  }
}

const logout = () => (dispatch, getState) => {
  removeToken();
  dispatch(toDispatch(constants.LOGOUT));
}

export const authActions = {
  load,
  login,
  logout,
  autoReLogin,
  loginWithGoogle,
  loginWithFacebook,
  loadToken,
};