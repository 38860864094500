import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useStyles from './style';

const Page = ({icono, titulo, contenido}) => {
  const classes = useStyles();
  return (
    <Grid container spacing={3} className={classes.root}>
      <Grid item xs={2} md={2} xl={1} className={classes.iconCell}>
        {icono}
      </Grid>
      <Grid item xs={10} md={10} xl={11}>
        <Typography gutterBottom variant="h5" component="h2">
          {titulo}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {contenido}
        </Typography>
      </Grid>

    </Grid>
 
  )
}

export default Page;